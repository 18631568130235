//言語設定（※日本語のみ）
angular.module('app').config(['$translateProvider', function($translateProvider) {
  $translateProvider
    .useStaticFilesLoader({
      prefix: '../lang/prop_',
      suffix: '.json'
    })
  .preferredLanguage('ja')
  .fallbackLanguage('ja')
  // angular-translateコンテンツ内でエスケープ処理をするように設定
  .useSanitizeValueStrategy('escapeParameters');
}]);

