let app = angular.module('app');

app.controller('ManageControl', ['$scope', '$http', '$state',
  function($scope, $http, $state) {
    const self = this;

    // 管理者以外の場合は「読み取り結果一覧」画面に遷移させる
    if(!$scope.isAdmin) {
      $state.go('ocr-jobs');
    }
}]);

