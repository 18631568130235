const app = angular.module('app');

app.config(['$compileProvider', function($compileProvider) {
  // dataDL時のURL指定時のみ「unsafe」を除去
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(data):/);
}]);

// OCR実行一覧/OCR実行結果確認画面で使用する変数
app.factory('OcrService', function() {
  return {
    targetJob: new Object(),
  };
});

// OCR実行
app.controller('OcrJobCreateCtrl', ['$scope', '$translate', '$state', '$q', '$timeout', '$transitions', '$window', 'ServerMsgService', 'FormListService',
  function($scope, $translate, $state, $q, $timeout, $transitions, $window, ServerMsgService, FormListService) {
    const self = this;
    // 初期値設定
    // factoryからデータ共有
    $scope.server = ServerMsgService;
    $scope.form = FormListService;
  
    // 読み取り対象ファイルの上限ファイルサイズ（MB）
    $scope.errFileRule = { size : 100 };

    // 初期化
    $scope.scanFiles = new Array();
    
    // 実行名のデフォルト値を取得するAPIを送信
    $scope.executeApi('GET', '/api/ocr/default/jobName')
    .then(
      // アクセス成功
      function() {
        // serverMsgAreaリセット
        $scope.serverMsgReset();

        let res = $scope.response.data;
        $scope.jobName = res.defaultJobName;
        
        // ローディング画面を閉じる処理
        $scope.endLoading();

      // アクセス失敗
      }, function() {
        // serverMsgAreaリセット
        $scope.serverMsgReset();

        // サーバーエラー
        $scope.server.error = true;
        $translate(['serverMsg.access-err'])
        .then(function (translations) {
          $scope.server.msg = translations['serverMsg.access-err'];
        });
        // ローディング画面を閉じる処理
        $scope.endLoading();
      }
    );

    // 各ファイルごとに処理を分岐 TODO 処理をまとめたい！！最低限関数化！！！！
    // スキャンファイル ///////////////////////////////////////
    $scope.$watchCollection("scanfile",function(files){
      self.selectScanFile(files);
    });

    self.selectScanFile = function(files) {

      // 初期表示時は処理しない
      if(!files){
        return;
      }

      // 読み取りファイルの許可している拡張子
      const allowedExtensions = ['jpg', 'jpeg', 'png', 'tif', 'tiff', 'pdf'];

      $scope.errScanFile = false;
      $scope.errScanFileRequired = false;
      $scope.errScanFileFormatUnified = false;

      $scope.errFileNames = new Array();

      let loadFile = function(file) {
        let d = $q.defer();

        // ファイル取得時の処理
        const reader = new FileReader();
        reader.onload = function(){
          $scope.$apply(function(){
            // ファイルの拡張子を取得
            const extPeriodIndex = file.name.indexOf('.');
            const format = file.name.slice(extPeriodIndex + 1).toLowerCase();

            // 読み込まれたファイルの拡張子を判定
            if(allowedExtensions.indexOf(format) == -1) {
              $scope.errFileNames.push(file.name);
              d.resolve();
            } else {
              // ファイルのバイナリデータを取得
              const dataStartIndex = reader.result.indexOf(',') + 1;
              const fileData = reader.result.slice(dataStartIndex);
              // 一覧へ追加
              self.addScanFile($scope.scanFiles.length, file.name, file.type, fileData, file.size);
              d.resolve();
            }
          });
          // タブを切り替える
          document.getElementById('tab-scanfile').click();
        };
        // ファイルURL読み込み
        reader.readAsDataURL(file);
        return d.promise;
      };

      let promises = new Array();
      angular.forEach(files, function(file) {
        promises.push(loadFile(file));
      });

      // 全てのファイルの読込が完了したら実行
      $q.all(promises)
      .then(function(){
        // エラーファイルがあった場合はエラーを表示
        if($scope.errFileNames.length > 0) {
          $scope.errScanFile = true;
          $scope.errScanFileFormat = true;
        }
      });
    };

    // 候補リストCSVファイル ///////////////////////////////////////
    /*$scope.$watch("csvfile",function(file){
      self.selectCsvFile(file);
    });

    self.selectCsvFile = function(file) {

      // 初期表示時は処理しない
      if(!file){
        return;
      }

      // ファイル取得時の処理
      const reader = new FileReader();
      reader.onload = function(){
        $scope.$apply(function(){
          // ファイル名表示
          $scope.csvFileName = file.name;

          // ファイルのバイナリデータと拡張子取得
          const dataStartIndex = reader.result.indexOf(',') + 1;
          const fileData = reader.result.slice(dataStartIndex);
          const extPeriodIndex = file.name.indexOf('.');
          const format = file.name.slice(extPeriodIndex + 1).toLowerCase();

          // CSVファイルで無い場合エラー表示
          if(format!='csv') {
            $scope.csvFileData = undefined;
            $scope.csvFileName = "";
            $scope.errCsvFile = true;
            $scope.errCsvFileRequired = false;
            $scope.errCsvFileFormat = true;
            $scope.errDataCsv = { errFileName : file.name };
          } else {
            $scope.csvFileData = fileData;
            $scope.errCsvFile = false;
            $scope.errCsvFileFormat = false;
          }

        });
      };
      // ファイルURL読み込み
      reader.readAsDataURL(file);
    };

    // 候補者CSVフォーマットDL処理
    self.candidateListFormatDL = function() {
      // セッションチェック(TODO:関数化したい)
      $scope.executeApi('GET', '/api/accounts/session')
      .then(function() {
        const res = $scope.response.data;
        if(typeof res.session === "undefined") {
          $window.location.href = '/views/login.html';
        } else {
          // ダウンロードファイル名設定
          $translate(['ocr.create.format-file-name'])
          .then(function (translations) {
            const fileName = translations['ocr.create.format-file-name'];
            // CSVフォーマットダウンロードAPI
            $scope.executeDLApi('/api/file/csv', fileName)
          .then(
            function() {
              // ファイルのダウンロード成功（処理無し）
            }, function() {
              // 失敗時はエラーメッセージを表示
              $scope.serverMsgReset();
              $scope.server.error = true;
              $translate(['serverMsg.access-err'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.access-err'];
              });
            });
          });
        }
      })
    };
    */

    self.outputPreview = function() {
      // DOMの状態の更新が終わるのを待つために$timeoutを使用
      $timeout(function(){
        angular.element('tr.info a.form-name').click();
      }, 0);
    };

    // factoryにformInfoが設定されていた場合は、そのフォームを選択状態にする
    $scope.$on('repeatEnd', function() {
      if(Object.keys($scope.form.formInfo).length!=0) {
        angular.element('#' + $scope.form.formInfo.formId).addClass('info');
        $scope.targetFormSelect = true;
        $scope.selectedForm = $scope.form.formInfo.name;
      };
    });

    // スキャンファイル表示設定
    // 追加
    self.addScanFile = function(idx, fileName, fileType, fileData, fileSize){
      // スキャンファイル情報設定
      $scope.scanFiles[idx] = {
        name: fileName,
        type: fileType,
        data: fileData,
        size: fileSize
      };
    };
    // 削除
    self.delScanFile = function(idx){
      $scope.scanFiles.splice(idx, 1);
    };

    // OCR実行処理
    self.executeOcr = function() {
      // 入力チェック
      if(self.inputErr()) {
        return;
      }

      // OCR実行用のオブジェクト作成
      let obj = new Object();
      obj.tags = [$scope.username, $scope.jobName, $scope.loginId];
      obj.formId = angular.element('tr.info')[0].id;
      obj.images = new Array();
      for(let i=0; i<$scope.scanFiles.length; i++) {
        // ファイルのバイナリデータと拡張子を設定
        const scanFile = $scope.scanFiles[i];
        const extPeriodIndex = scanFile.name.indexOf('.');

        obj.images[i] = new Object();
        obj.images[i].format =scanFile.name.slice(extPeriodIndex + 1);
        obj.images[i].data = scanFile.data;
      }
      //obj.candidateData = $scope.csvFileData;

      let loadingMsg = "";
      // 読込中メッセージを設定
      $translate(['loadingMsg.ocr-job-create'])
      .then(function (translations) {
        loadingMsg = translations['loadingMsg.ocr-job-create'];
      })
      .then(function() {
        // ローディング画面を表示
        $scope.startLoading(loadingMsg);
      });

      // OCR実行API
      $scope.executeApi('POST', '/api/ocr', angular.toJson(obj))
      .then(
        // 成功時はOCR実行情報を表示
        function() {
          let res = $scope.response.data;
          // serverMsgAreaリセット
          $scope.serverMsgReset();

          $scope.server.info = true;
          let targetJobData = {
                jobName: $scope.jobName,
                type: ""
              };
          $translate(['ocr.create.execute-reserve'])
          .then(function (translations) {
            targetJobData.type = translations['ocr.create.execute-reserve'];
          })
          .then(function() {
            $scope.server.info = true;
            $translate(['serverMsg.ocr-fix'], targetJobData)
            .then(function (translations) {
              $scope.server.msg = translations['serverMsg.ocr-fix'];
            });
          })
          .then(function() {
            // ローディング画面を閉じる処理
            $scope.endLoading();
            // OCR実行一覧画面に遷移
            $state.go('ocr-jobs');
          });

        // 失敗時はエラーメッセージを表示
        }, function() {
          // serverMsgAreaリセット
          $scope.serverMsgReset();

          // CSVフォーマットエラー
          if($scope.response.data.ERROR_CODE=='CSV_FORMAT_ERROR') {
            $scope.csvFileData = undefined;
            $scope.errCsvFile = true;
            $scope.errCsvFileFormat = true;
            $scope.errDataCsv = { errFileName : $scope.csvFileName };
            $scope.csvFileName = "";
            angular.element(document.querySelector('#csvfile-form')).val(null);

          // サーバエラー
          } else {
            $scope.server.error = true;
            $translate(['serverMsg.access-err'])
            .then(function (translations) {
              $scope.server.msg = translations['serverMsg.access-err'];
            });
          }
          // ローディング画面を閉じる処理
          $scope.endLoading();
        }
      )
    };

    // 入力チェック処理
    self.inputErr = function() {
      let error = false;
      $scope.errJobName = false;
      $scope.errTargetForm = false;
      $scope.errScanFile = false;
      $scope.errScanFileFormat = false;
      $scope.errScanFileFormatUnified = false;
      $scope.errCsvFile = false;
      $scope.errCsvFileRequired = false;
      $scope.errCsvFileFormat = false;

      // 実行ジョブ名
      if(!$scope.jobName) {
        $scope.errJobName = true;
        error = true;
      }
      // 対象フォーム
      if(!$scope.targetFormSelect) {
        $scope.errTargetForm = true;
        error = true;
      }
      // スキャンファイル(必須チェック)
      if(!$scope.scanFiles || $scope.scanFiles.length<=0) {
        $scope.errScanFile = true;
        $scope.errScanFileRequired = true;
        error = true;
      } else {
        // スキャンファイル(拡張子チェック)
        // ファイルの拡張子を取得
        let targetFileName = $scope.scanFiles[0].name;
        let fileSize = $scope.scanFiles[0].size;
        const targetExtPeriodIndex = targetFileName.indexOf('.');
        const targetFormat = targetFileName.slice(targetExtPeriodIndex + 1).toLowerCase();
        for(let i = 1; i < $scope.scanFiles.length; i++) {
          // ファイルの拡張子を取得
          let fileName = $scope.scanFiles[i].name;
          const extPeriodIndex = fileName.indexOf('.');
          const format = fileName.slice(extPeriodIndex + 1).toLowerCase();
          // 拡張子の一致チェック
          if(targetFormat != format) {
            $scope.errScanFile = true;
            $scope.errScanFileFormatUnified = true;
            error = true;
            break;
          }
          // ファイルサイズを取得
          fileSize = fileSize + $scope.scanFiles[i].size;
        }
        // MBに変換
        const sizeMb = fileSize/1024**2;
        // ファイルサイズチェック          
        if(!error && sizeMb > $scope.errFileRule.size) {
          $scope.errScanFile = true;
          $scope.errScanFileSize = true;
          error = true;
        }
      }
      // 候補リストファイル
      /*if(!$scope.csvFileData) {
        $scope.errCsvFile = true;
        $scope.errCsvFileRequired = true;
        error = true;
      }*/
      return error;
    }

    // OCR実行画面から離れたときを監視し、factoryを初期化する
    // TODO 画面遷移の監視が複数回行われるため修正
    $transitions.onStart({from: 'ocr-job-create'}, function(trans) {
      $scope.form.formInfo = new Object();
      $scope.form.formImgF = "";
      $scope.form.formImgB = "";
      $scope.form.formNoBody = true;
    });

  }
]);

// OCR実行一覧
app.controller('OcrJobsCtrl', ['$scope', '$translate', '$filter', '$state', '$q', '$interval', '$window', '$sce', 'ServerMsgService', 'OcrService',
  function($scope, $translate, $filter, $state, $q, $interval, $window, $sce, ServerMsgService, OcrService) {

        const self = this;
        // 初期値設定
        // factoryからデータ共有
        $scope.server = ServerMsgService;
        $scope.ocr = OcrService;

        $scope.jobsInfo = new Array();

        let data = {
          tags : new Array(),
          days : 0,
          loginId: "",
        };

        let checkLoginId = new Object();

        // IDを取得するAPIの実行が完了しているかチェックする
        let checkLoginIdPromise = function() {
          let d = $q.defer();
          // IDをすでに取得している場合はpromiseを解決する
          if($scope.loginId) {
            d.resolve();
          };
          // IDの取得を0.5秒ごとに確認し、取得したらpromiseを解決する
          checkLoginId = $interval(function() {
            if($scope.loginId) {
              d.resolve();
            };
          }, 500)
          return d.promise;
        };

        checkLoginIdPromise()
        .then(function() {
          // 繰り返し処理を終了する
          $interval.cancel(checkLoginId);

          // ログイン中のログインIDを設定
          data.tags[0] = $scope.loginId;
          data.loginId = $scope.loginId;
          
          let loadingMsg = "";
          // 読込中メッセージを設定
          $translate(['loadingMsg.ocr-jobs'])
          .then(function (translations) {
            loadingMsg = translations['loadingMsg.ocr-jobs'];
          })
          .then(function() {
            // ローディング画面を表示
            $scope.startLoading(loadingMsg);
          });

          // ジョブ一覧取得APIを実行
          let resStatus = true;
          $scope.executeApi('POST', '/api/jobs', data)
          .then(
            // アクセス成功
            function() {
              let res = $scope.response.data;
              let ocrJobs = new Array();
              
              for(let i = 0; i < res.length; i++) {
                // 日付のフォーマットを指定
                let date = new Date( res[i].created * 1000 );
                let createdDate = formatDate(date, 'yyyy/MM/dd hh:mm:ss');
                // セッションが切れているか確認
                if(typeof res[i].status === "undefined") {
                    resStatus = false;
                    break;
                }

                // ジョブの状態によってアイコンとポップオーバーのメッセージを設定
                // 「ACCEPTED」の場合
                if(res[i].status == 'ACCEPTED') {
                  let msg = '';
                  $translate(['ocr.jobs.accepted'])
                  .then(function (translations) {
                    msg = translations['ocr.jobs.accepted'];
                  })
                  .then(function() {
                    $scope.jobsInfo[i] = {
                      msg: $sce.trustAsHtml(msg),
                      icon: 'glyphicon-time',
                      displayEdit: false,
                      displayDownload: false,
                      displayDelete: false,
                      displayLms: false,
                      displayCancel: true,
                    };
                  });
                // 「RECOGNIZING」の場合
                } else if(res[i].status == 'RECOGNIZING') {
                  let msg = '';
                  $translate(['ocr.jobs.recognizing'])
                  .then(function (translations) {
                    msg = translations['ocr.jobs.recognizing'];
                  })
                  .then(function() {
                    $scope.jobsInfo[i] = {
                      msg: $sce.trustAsHtml(msg),
                      icon: 'glyphicon-refresh',
                      displayEdit: false,
                      displayDownload: false,
                      displayDelete: false,
                      displayLms: false,
                      displayCancel: false,
                    };
                  });
                // 「FINISHED」または「CORRECTING」の場合
                } else if(res[i].status == 'FINISHED' || res[i].status == 'CORRECTING') {
                  let msg = '';
                  $translate(['ocr.jobs.finished', 'ocr.jobs.correcting'])
                  .then(function (translations) {
                    if(res[i].status == 'CORRECTING') {
                      msg = translations['ocr.jobs.correcting'];
                    } else {
                      msg = translations['ocr.jobs.finished'];
                    }
                  })
                  .then(function() {
                    $scope.jobsInfo[i] = {
                      msg: $sce.trustAsHtml(msg),
                      icon: 'glyphicon-record',
                      displayEdit: true,
                      displayDownload: true,
                      displayDelete: true,
                      displayLms: false,
                      displayCancel: false,
                    };
                  });
                // 「CONFIRMED」の場合
                } else if(res[i].status == 'CONFIRMED') {
                  let msg = '';
                  $translate(['ocr.jobs.confirmed'])
                  .then(function (translations) {
                    msg = translations['ocr.jobs.confirmed'];
                  })
                  .then(function() {
                    $scope.jobsInfo[i] = {
                      msg: $sce.trustAsHtml(msg),
                      icon: 'glyphicon-ok-circle',
                      displayEdit: true,
                      displayDownload: true,
                      displayDelete: true,
                      displayLms: true,
                      displayCancel: false,
                    };
                  });
                // 「INVALID」の場合
                } else if(res[i].status == 'INVALID') {
                  let msg = '';
                  $translate(['ocr.jobs.invalid'])
                  .then(function (translations) {
                    msg = translations['ocr.jobs.invalid'];
                  })
                  .then(function() {
                    $scope.jobsInfo[i] = {
                      msg: $sce.trustAsHtml(msg),
                      icon: 'glyphicon-remove',
                      displayEdit: false,
                      displayDownload: false,
                      displayDelete: true,
                      displayLms: false,
                      displayCancel: false,
                    };
                  });
                //「ABORTED」の場合
                } else if(res[i].status == 'ABORTED') {
                  let msg = '';
                  $translate(['ocr.jobs.aborted'])
                  .then(function (translations) {
                    msg = translations['ocr.jobs.aborted'];
                  })
                  .then(function() {
                    $scope.jobsInfo[i] = {
                      msg: $sce.trustAsHtml(msg),
                      icon: 'glyphicon-remove',
                      displayEdit: false,
                      displayDownload: false,
                      displayDelete: false, // 異常終了の場合は原因調査のためユーザーから削除できないようにする
                      displayCancel: false,
                    };
                  });
                }
                ocrJobs[i] = {
                  created: createdDate,
                  formName: res[i].formName,
                  jobId: res[i].jobId,
                  status: res[i].status,
                  jobName: res[i].tags[1],
                  creater: res[i].tags[0],
                };
              };
              $scope.jobList = ocrJobs;
              // ローディング画面を閉じる処理
              $scope.endLoading();
              // セッションが切れていた場合、トップ画面へ遷移する
              if(!resStatus) {
                  resStatus = true;
                  $window.location.href = '/views/login.html';
              }
            // アクセス失敗
            }, function() {
              $scope.serverMsgReset();
              $scope.server.error = true;
              
              // 権限のないフォームへのアクセス
              if($scope.httpStatus==403) {
                $translate(['serverMsg.invalid-access'])
                .then(function (translations) {
                  $scope.server.msg = translations['serverMsg.invalid-access'];
                });
              } else {
                $translate(['serverMsg.access-err'])
                .then(function (translations) {
                  $scope.server.msg = translations['serverMsg.access-err'];
                });
              }
              // ローディング画面を閉じる処理
              $scope.endLoading();
            }
          );
        }
      );

    // OCR結果確認画面に遷移するときの処理
    self.transResultEdit = function(listIdx) {
      const tmpJob = $scope.jobList[listIdx];
      if(tmpJob) {
        $scope.ocr.targetJob = tmpJob;

        // serverMsgAreaリセット
        $scope.serverMsgReset();
        // SessionStorageを削除
        sessionStorage.clear();
        // OCR実行結果確認画面へ遷移
        $state.go('ocr-result-edit');

      } else {
        // serverMsgAreaリセット
        $scope.serverMsgReset();
        $scope.server.error = true;
        $translate(['serverMsg.jobid-not-exist'], tmpJob)
        .then(function (translations) {
          $scope.server.msg = translations['serverMsg.jobid-not-exist'];
        });
      }
    }
    
    // LMS連携
    self.cooperationLms = function(listIdx) {      
      // セッションチェック(TODO:関数化したい)
      $scope.executeApi('GET', '/api/accounts/session')
      .then(function() {
        const res = $scope.response.data;
        if(typeof res.session === "undefined") {
          $window.location.href = '/views/login.html';
        } else {
          let loadingMsg = "";
          // 読込中メッセージを設定
          $translate(['loadingMsg.ocr-result-cooperation'])
          .then(function (translations) {
            loadingMsg = translations['loadingMsg.ocr-result-cooperation'];
          })
          .then(function() {
            // ローディング画面を表示
            $scope.startLoading(loadingMsg);
          });

           // LMS連携API呼び出し
          $scope.executeApi('GET', '/api/ocr/' + $scope.jobList[listIdx].jobId + '/recognition/cooperation')
           .then(
             // アクセス成功
             function() {
               // serverMsgAreaリセット
               $scope.serverMsgReset();
               
               // 完了時のメッセージに使用するデータを用意
               let targetJobData = {
                 jobName: $scope.jobList[listIdx].jobName
               }
               $translate(['cooperation'])
               .then(function (translations) {
                 targetJobData.type = translations['cooperation'];
               })
               .then(function() {
                 $scope.server.info = true;
                 $translate(['serverMsg.ocr-fix'], targetJobData)
                 .then(function (translations) {
                   $scope.server.msg = translations['serverMsg.ocr-fix'];
                 });
               })
               
               // ローディング画面を閉じる処理
               $scope.endLoading();
               
               let res = $scope.response.data;
               
               let idnumber = res.idnumber;
               let reportId = res.reportId;
               let personalId = res.personalId;
               let zipFilePath = res.zipFilePath;
               let lmsUrl = res.lmsUrl;
               let params = 'idnumber=' + idnumber + '&reportId=' + reportId + '&teacherId=' + personalId + '&zipFilePath=' + zipFilePath;
               $window.location.href = lmsUrl + params;
        
             // アクセス失敗
             }, function() {
               // serverMsgAreaリセット
               $scope.serverMsgReset();
        
               // サーバーエラー
               $scope.server.error = true;
               $translate(['serverMsg.access-err'])
               .then(function (translations) {
                 $scope.server.msg = translations['serverMsg.access-err'];
               });
               // ローディング画面を閉じる処理
               $scope.endLoading();
            });
          }
        }
      )
    }

    // OCR結果ダウンロード処理
    self.dlResult = function(listIdx) {
      // セッションチェック(TODO:関数化したい)
      $scope.executeApi('GET', '/api/accounts/session')
      .then(function() {
        const res = $scope.response.data;
        if(typeof res.session === "undefined") {
          $window.location.href = '/views/login.html';
        } else {
          const filenameData = {
            time: $scope.jobList[listIdx].created.replace(/ /g, '_').replace(/\//g, '').replace(/\:/g, '')
          };

          $translate(['ocr.jobs.zip-filename'], filenameData)
          .then(function (translations) {
            let loadingMsg = "";
            // 読込中メッセージを設定
            $translate(['loadingMsg.ocr-result-download'])
            .then(function (translations) {
              loadingMsg = translations['loadingMsg.ocr-result-download'];
            })
            .then(function() {
              // ローディング画面を表示
              $scope.startLoading(loadingMsg);
            });

            // OCR結果ダウンロード処理
            $scope.executeDLApi('api/ocr/' + $scope.jobList[listIdx].jobId  + '/recognition/download', translations['ocr.jobs.zip-filename'])
            .then(
              // 成功時
              function() {
                // ファイルのダウンロード成功（処理無し）
                // ローディング画面を閉じる処理
                $scope.endLoading();

              // 失敗時はエラーメッセージを表示
              }, function() {
                // serverMsgAreaリセット
                $scope.serverMsgReset();
                $scope.server.error = true;
                
                // 権限のないフォームへのアクセス
                if($scope.httpStatus==403) {
                  $translate(['serverMsg.invalid-access'])
                  .then(function (translations) {
                    $scope.server.msg = translations['serverMsg.invalid-access'];
                  });
                } else {
                  $translate(['serverMsg.jobid-not-exist'], $scope.jobList[listIdx])
                  .then(function (translations) {
                    $scope.server.msg = translations['serverMsg.jobid-not-exist'];
                  })
                }
                // ローディング画面を閉じる処理
                $scope.endLoading();
              });
            })
          }
        }
      )
    }

    // 削除/中止ポップアップの表示処理
    self.setModalMsg = function(listIdx) {
      let action;
      // ジョブの削除か中止かを判定
      if($scope.jobsInfo[listIdx].displayDelete) {
        action = 'delete';
      } else {
        action = 'cancel';
      }
      $translate(['ocr.jobs.' + action + '-title', 'ocr.jobs.' + action + '-msg'])
      .then(function (translations) {
        $scope.modalTitle = translations['ocr.jobs.' + action + '-title'];
        $scope.modalMsg = $sce.trustAsHtml(translations['ocr.jobs.' + action + '-msg']);
      })
      $scope.targetIdx = listIdx;
    }

    // ジョブの削除/中止処理
    self.deleteJob = function(targetIdx) {
      let action;
      // ジョブの削除か中止かを判定
      if($scope.jobsInfo[targetIdx].displayDelete) {
        action = 'delete';
      } else {
        action = 'cancel';
      }
      let loadingMsg = "";
      // 読込中メッセージを設定
      $translate(['loadingMsg.ocr-job-' + action])
      .then(function (translations) {
        loadingMsg = translations['loadingMsg.ocr-job-' + action];
      })
      .then(function() {
        // ローディング画面を表示
        $scope.startLoading(loadingMsg);
      });

      // ジョブの削除APIを実行
      $scope.executeApi('DELETE', '/api/jobs/' + $scope.jobList[targetIdx].jobId)
      .then(
        // アクセス成功
        function() {
          // serverMsgAreaリセット
          $scope.serverMsgReset();

          // 完了時のメッセージに使用するデータを用意
          let targetJobData = {
            jobName: $scope.jobList[targetIdx].jobName
          }
          if(action === 'delete') {
            $translate(['delete'])
            .then(function (translations) {
              targetJobData.type = translations['delete'];
            })
            .then(function() {
              $scope.server.info = true;
              $translate(['serverMsg.ocr-fix'], targetJobData)
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.ocr-fix'];
              });
            })
          } else {
            $scope.server.info = true;
            $translate(['serverMsg.job-cancel'], targetJobData)
            .then(function (translations) {
              $scope.server.msg = translations['serverMsg.job-cancel'];
            });
          }
          // ローディング画面を閉じる処理
          $scope.endLoading();
          $state.reload();

        // アクセス失敗
        }, function() {
          // serverMsgAreaリセット
          $scope.serverMsgReset();

          // 権限のないフォームへのアクセス
          if($scope.httpStatus==403) {
          $scope.server.error = true;
            $translate(['serverMsg.invalid-access'])
            .then(function (translations) {
              $scope.server.msg = translations['serverMsg.invalid-access'];
            });
          // 指定されたIDのフォームが存在しない
          } else if($scope.httpStatus==404) {
            $scope.server.error = true;
            let targetJobData = {
              jobName: $scope.jobList[targetIdx].jobName
            }
            $translate(['serverMsg.jobid-not-exist'], targetJobData)
            .then(function (translations) {
              $scope.server.msg = translations['serverMsg.jobid-not-exist'];
            });
          // すでにOCR実行中となっているため中止できない
          } else if($scope.httpStatus==409) {
            $scope.server.warn = true;
            $translate(['serverMsg.job-already-executing'])
            .then(function (translations) {
              $scope.server.msg = translations['serverMsg.job-already-executing'];
            });
          // サーバーエラー
          } else {
            $scope.server.error = true;
            $translate(['serverMsg.access-err'])
            .then(function (translations) {
              $scope.server.msg = translations['serverMsg.access-err'];
            });
          }
          // ローディング画面を閉じる処理
          $scope.endLoading();
        }
      );
    }
  }
]);

// OCR実行結果確認/編集
app.controller('OcrResultCtrl', ['$scope', '$translate', '$state', '$filter', '$window', '$transitions', '$sce', 'ServerMsgService', 'OcrService',
  function($scope, $translate, $state, $filter, $window, $transitions, $sce, ServerMsgService, OcrService) {

      const self = this;
        // 初期値設定
        $scope.editValue = new Array();

        // factoryからデータ共有
        $scope.server = ServerMsgService;
        $scope.ocr = OcrService;

        let onBeforeunloadHandler = function(event) {
          sessionStorage.setItem('ocr', JSON.stringify($scope.ocr) );
          sessionStorage.setItem('server', JSON.stringify($scope.server) );
        };

        // ページの再読み込みを監視する
        $window.addEventListener('beforeunload', onBeforeunloadHandler, false);

        // SessionStorageから値を取得
        const tmpOcr = JSON.parse( sessionStorage.getItem('ocr') );
        const tmpServer = JSON.parse( sessionStorage.getItem('server') );
        if(tmpOcr) {
          $scope.ocr = tmpOcr;
          // OcrServiceの値を共有している為、個別に設定
          $scope.server.info = tmpServer.info;
          $scope.server.warn = tmpServer.warn;
          $scope.server.error = tmpServer.error;
          $scope.server.msg = tmpServer.msg;
          // SessionStorageを削除
          sessionStorage.clear();
        }

        let loadingMsg = "";
        // 読込中メッセージを設定
        $translate(['loadingMsg.ocr-result'])
        .then(function (translations) {
          loadingMsg = translations['loadingMsg.ocr-result'];
        })
        .then(function() {
          // ローディング画面を表示
          $scope.startLoading(loadingMsg);
        });

        $scope.executeApi('GET', 'api/ocr/' + $scope.ocr.targetJob.jobId  + '/recognition')
        .then(
          // アクセス成功
          function() {
            // 実行一覧表示用に展開
            let res = $scope.response.data;
            $scope.ocrResult = new Object();
            $scope.ocrResult.jobId = res.jobId;

            // セッションが切れている場合、jobIdが取得できないのでチェック
            if(typeof $scope.ocrResult.jobId !== "undefined") {
              $scope.ocrResult.author = res.appData[0];
              $scope.ocrResult.jobName = res.appData[1];
              // 確認完了済みかのステータス設定
              $scope.ocrResult.jobStatus = ($scope.ocr.targetJob.status == "CONFIRMED");
              // tagsを設定
              $scope.ocrResult.tags = res.tags;
              // ユーザ毎のデータ設定
              $scope.ocrResult.userResultList = new Array();

              for(let i=0; i< res.result.length; i++) {
                // 一覧に表示するのは「表紙」のみ ※本来それ以外のデータは来ない
                if(res.result[i].class=='COVER') {
                  let userResult = new Object();
                  let tmpPdfNameList = new Array();
                  userResult.status = '';
                  userResult.items = new Array();
                  userResult.sheetNo = res.result[i].sheetNo;

                  // OCR認識項目の整形
                  for(let j=0; j< res.result[i].detectedItems.length; j++) {
                    let item = new Object();
                    item.name = res.result[i].detectedItems[j].name;
                    // imageがnullでない場合は読み取った値と画像を設定する
                    if(res.result[i].detectedItems[j].image != null) {
                      item.value = res.result[i].detectedItems[j].value;
                      item.rect = res.result[i].detectedItems[j].rect;
                      item.image = 'data:image/'+ res.result[i].detectedItems[j].image.format + ';base64,' + res.result[i].detectedItems[j].image.data;
                    }
                    item.scrap = false;
                    // 対象の項目情報を設定（表示順・必須フラグ・組み合わせ番号）
                    const targetItem = $filter('filter')(res.registerItems, {name: res.result[i].detectedItems[j].name})[0];
                    // 対象の項目情報が項目マスタに存在しなかった場合、何も設定しない
                    if(targetItem==undefined) {
                      continue;
                    }
                    item.displayOrder = targetItem.displayOrder;
                    item.required = targetItem.required;
                    item.group = targetItem.group;
                    userResult.items.push(item);

                    // PDF名作成用の文字列を設定（scraps以外で[必須]または[組み合わせ（一意）]のvalue）
                    if(item.required || item.group!=0) {
                      let tmpPdfName = new Object();
                      tmpPdfName.displayOrder = item.displayOrder;
                      tmpPdfName.value = item.value;
                      tmpPdfNameList.push(tmpPdfName);
                    }

                    // 結果ステータス【警告】（認識結果の信頼度）
                    if(userResult.status==='') {
                      if(!res.result[i].detectedItems[j].reliable) {
                        userResult.status = 'unreliable';
                        $translate(['ocr.result.status-msg3'])
                        .then(function (translations) {
                          userResult.statusMsg = translations['ocr.result.status-msg3'];
                        });
                      }
                    }
                  }

                  // 画像切り取り項目（scraps）の整形
                  for(let j=0; j< res.result[i].scraps.length; j++) {
                    let item = new Object();
                    item.name = res.result[i].scraps[j].name;
                    // imageがnullでない場合は読み取った値と画像を設定する
                    if(res.result[i].scraps[j].image != null) {
                      item.value = res.result[i].scraps[j].value;
                      item.rect = res.result[i].scraps[j].rect;
                      item.image = 'data:image/'+ res.result[i].scraps[j].image.format + ';base64,' + res.result[i].scraps[j].image.data;
                    }
                    item.scrap = true;
                    // 対象の項目情報を設定（表示順・必須フラグ・組み合わせ番号）
                    const targetItem = $filter('filter')(res.registerItems, {name: res.result[i].scraps[j].name})[0];
                    // 対象の項目情報が項目マスタに存在しなかった場合、何も設定しない
                    if(targetItem==undefined) {
                      continue;
                    }
                    item.displayOrder = targetItem.displayOrder;
                    item.required = targetItem.required;
                    item.group = targetItem.group;
                    userResult.items.push(item);
                  }

                  // フォームに登録されている項目と比較
                  let addItems = new Array();
                  angular.forEach(res.registerItems, function(registerItem) {
                    const targetItem = $filter('filter')(userResult.items, {name: registerItem.name})[0];
                    // 存在しない項目がある場合は仮の値を設定する
                    if(targetItem==undefined) {
                      let item = new Object();
                      item.name = registerItem.name;
                      item.value = '';
                      item.rect = [0,0,100,50];
                      item.image = "img/noImage.png";
                      item.scrap = registerItem.scrap;
                      item.displayOrder = registerItem.displayOrder;
                      item.required = registerItem.required;
                      item.group = registerItem.group;
                      addItems.push(item);
                    }
                  });
                  angular.forEach(addItems, function(addItem) {
                    userResult.items.push(addItem);
                  });

                  // DL時のpdfファイル名生成（ソート順に設定）
                  let pdfName = '';
                  tmpPdfNameList.sort(function(a,b){
                    if( a.displayOrder < b.displayOrder ) {
                      return -1;
                    }
                    if( a.displayOrder > b.displayOrder ) {
                      return 1;
                    }
                    return 0;
                  });
                  for(let j=0; j<tmpPdfNameList.length; j++) {
                    pdfName += tmpPdfNameList[j].value + '_';
                  }

                  // PDF、結果編集画面用サムネイル設定
                  userResult.pdf = new Object();
                  userResult.pdf.name = pdfName.slice(0,-1) + "." + res.result[i].pdfModel.format;
                  userResult.pdf.type = 'application/' + res.result[i].pdfModel.format;
                  userResult.pdf.data = res.result[i].pdfModel.data;
                  userResult.thumbnailData = 'data:image/' + res.result[i].pdfModel.image.format + ';base64,' + res.result[i].pdfModel.image.data;

                  $scope.ocrResult.userResultList.push(userResult);
                }
              }

              // 項目マスタ情報の設定
              $scope.itemMstList = new Array();
              let aliasMainMsg = '';
              $translate(['alias-msg'])
              .then(function (translations) {
                aliasMainMsg = translations['alias-msg'];
              })
              .then(function() {
                for(let i=0; i<res.registerItems.length; i++) {
                  let aliasMsg = '';
                  for(let j=0; j<res.registerItems[i].aliases.length; j++) {
                    if(aliasMsg==='') {
                      aliasMsg += aliasMainMsg + "<br />・" + res.registerItems[i].aliases[j];
                    } else {
                      aliasMsg += "<br />・" + res.registerItems[i].aliases[j];
                    }
                  }
                  res.registerItems[i].aliasMsg = $sce.trustAsHtml(aliasMsg);
                  $scope.itemMstList.push(res.registerItems[i]);
                }

                // 項目マスタ数に応じて表示列幅変更（ステータスアイコン・操作列を除き、画像・値の2列分で分割）
                $scope.resultColWidth = 88.99 / ($scope.itemMstList.length * 2);
              });

              // 重複チェック等を行う
              self.checkListErr()
            }

            // ローディング画面を閉じる処理
            $scope.endLoading();

            // セッションが切れている場合、トップ画面へ遷移する
            if(typeof $scope.ocrResult.jobId === "undefined") {
                $window.location.href = '/views/login.html';
            }
          // アクセス失敗
          }, function() {
            // serverMsgAreaリセット
            $scope.serverMsgReset();

            $scope.server.error = true;
            // 権限のないフォームへのアクセス
            if($scope.httpStatus==403) {
              $translate(['serverMsg.invalid-access'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.invalid-access'];
              });
            // 指定されたジョブIDが存在しない
            } else if($scope.httpStatus==404) {
              $translate(['serverMsg.jobid-not-exist'], $scope.ocr.targetJob)
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.jobid-not-exist'];
              });
            // システムエラー
            } else {
              $translate(['serverMsg.access-err'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.access-err'];
              })
            };

            // ローディング画面を閉じる処理
            $scope.endLoading();
            // OCR実行一覧画面へ遷移
            $state.go('ocr-jobs');
          }
        );

    // 表示内容の検査（重複・空欄チェック）
    self.checkListErr = function() {
      // 重複チェック用のMap作成
      let multiValuesMap = new Map();
      let allValuesMap = new Map();
      let multiMapIdx = 0;
      let allMapIdx = 0;
      angular.forEach($scope.ocrResult.userResultList, function(userResult) {
        // 対象の項目情報を設定(「OCR解析項目」かつ「組み合わせあり」の場合のみ)
        let usersGroupList = new Array();
        let gourpIdx = 0;
        let matchFlg = false;
        angular.forEach(userResult.items, function(item) {
          if(!item.scrap && item.group!=0) {
            let tmpItem = new Object();
            tmpItem.name = item.name;
            tmpItem.value = item.value;
            let groupCount;
            for(groupCount=0; groupCount<usersGroupList.length; groupCount++) {
              if(item.group==usersGroupList[groupCount].group) {
                usersGroupList[groupCount].items.push(tmpItem);
                break;
              }
            }
            if(groupCount==usersGroupList.length) {
              usersGroupList[gourpIdx] = new Object();
              usersGroupList[gourpIdx].group = item.group;
              usersGroupList[gourpIdx].items = new Array();
              usersGroupList[gourpIdx].items.push(tmpItem);
              gourpIdx++;
            }
          }
        });

        // 重複がある場合、重複Mapに追加
        angular.forEach(usersGroupList, function(usersGroup) {
          // 初回設定時は配列を初期化
          if(!allValuesMap.get(usersGroup.group)) {
            allValuesMap.set(usersGroup.group, new Array());
          }
          if(!allValuesMap.get(usersGroup.group)[allMapIdx]) {
            allValuesMap.get(usersGroup.group)[allMapIdx] = new Array();
          }
          // allMapから一致する情報があるか検索する
          angular.forEach(allValuesMap.get(usersGroup.group), function(allValues) {
            let allMatchCount = 0;
            angular.forEach(usersGroup.items, function(userItem) {
              angular.forEach(allValues, function(item) {
                if(angular.equals(userItem, item)) {
                  allMatchCount++;
                }
              });
            });
            // 一致した場合、multiMapに既に存在するか確認
            if(allMatchCount==usersGroup.items.length) {
              matchFlg = true;
              // 初回設定時は配列を初期化
              if(!multiValuesMap.get(usersGroup.group)) {
                multiValuesMap.set(usersGroup.group, new Array());
              }
              multiValuesMap.get(usersGroup.group)[multiMapIdx] = usersGroup.items;
              multiMapIdx++;
            }
          });
        });

        // 重複していない場合、valueを比較対象のallMapに追加
        if(!matchFlg) {
          angular.forEach(usersGroupList, function(usersGroup) {
            allValuesMap.get(usersGroup.group)[allMapIdx] = usersGroup.items;
          });
          allMapIdx++;
        }
      });

      // 項目マスタ情報が設定されていない場合、0.2秒処理を待つ
      let errorCnt = 0;
      let timer = setInterval(function() {
        if($scope.itemMstList.length>0) {
          clearInterval(timer);
          self.checkMain(multiValuesMap, allValuesMap);
        } else {
          errorCnt++;
        }
        if(errorCnt==10) {
          console.log('【ERROR】項目マスタ情報が取得できない為、遷移できません。');
          return;
        }
      }, 200);
    }

    // 重複・空欄チェックのメイン処理
    self.checkMain = function(multiValuesMap, allValuesMap) {
      // 組み合わせリスト作成
      let groupKeyList = new Array();
      angular.forEach($scope.itemMstList, function(itemMst) {
        if(itemMst.group!=0) {
          const group = $filter('filter')(groupKeyList, itemMst.group);
          // 設定されていない場合は、新たに値を設定する
          if(group=='') {
            groupKeyList.push(itemMst.group);
          }
        }
      });

      // 重複・空欄チェック処理
      let totalCount = 0;
      let warnCount = 0;
      $scope.ocrResult.isErr = false;
      angular.forEach($scope.ocrResult.userResultList, function(userResult) {
        // 空欄チェック（空、undefined、nullをチェック）
        angular.forEach(userResult.items, function(userItem) {
          if(userItem.value==undefined || userItem.value=="" || userItem.value==null) {
            userResult.status = 'empty';
            $translate(['ocr.result.status-msg2'])
            .then(function (translations) {
              userResult.statusMsg = translations['ocr.result.status-msg2'];
            });
          }
        });

        // 重複エラーの場合、初期化
        if(userResult.status=='multi') {
          userResult.status = '';
          userResult.statusMsg = undefined;
        }

        // 重複チェック処理（空欄はチェック不要）
        if(userResult.status!='empty') {
          angular.forEach(groupKeyList, function(groupKey) {
            const multiGroupsList = multiValuesMap.get(groupKey);
            // 該当のmultiGroupsListが存在し、重複エラーでない場合
            if(multiGroupsList && userResult.status!='multi') {
              // 重複チェック対象の全項目数カウント
              let allCount;
              // 重複項目数カウント
              let multiCount;

              for(let multiGroupsIdx=0; multiGroupsIdx<multiGroupsList.length; multiGroupsIdx++) {
                const multiGroups = multiGroupsList[multiGroupsIdx];
                // 初期化
                allCount = 0;
                multiCount = 0;

                angular.forEach(userResult.items, function(userItem) {
                  // scrap、組み合わせありの項目もチェック不要
                  if(!userItem.scrap && userItem.group!=0) {
                    allCount++;
                    let tmpGroup = new Object();
                    tmpGroup.name = userItem.name;
                    tmpGroup.value = userItem.value;
                    for(let groupsCount=0; groupsCount<multiGroups.length; groupsCount++) {
                      if(angular.equals(multiGroups[groupsCount], tmpGroup)) {
                        multiCount++;
                        break;
                      }
                    }
                  }
                });

                // チェック対象がすべて一致した場合、重複エラーを設定して処理を抜ける
                if(allCount==multiCount) {
                  userResult.status = 'multi';
                  $translate(['ocr.result.status-msg1'])
                  .then(function (translations) {
                    userResult.statusMsg = translations['ocr.result.status-msg1'];
                  });
                  break;
                }
              }

            }
          });
        }

        // 総数カウント
        totalCount++;
        // 警告・エラー数カウント
        if(userResult.status) {
          warnCount++;
        }
        // エラーが存在する場合は確認ボタンを非活性
        if(userResult.status && userResult.status != 'unreliable') {
          $scope.ocrResult.isErr = true;
        }
      });
      $scope.$apply(function() {
        $scope.ocrResult.totalCount = totalCount;
        $scope.ocrResult.warnCount = warnCount;
      });
    }

    const upSortIcon = "▲";
    const downSortIcon = "▼";

    // 実行結果修正画面用データ取得・設定
    self.editResult = function(idx) {
      // セッションチェック(TODO:関数化したい)
      $scope.executeApi('GET', '/api/accounts/session')
      .then(function() {
        const res = $scope.response.data;
        if(typeof res.session === "undefined") {
          $window.location.href = '/views/login.html';
        } else {
            $scope.targetUserIdx = idx;

            // 修正用の値を設定
            $scope.candidateDataList = new Array();
            $scope.noCandidateDataList = new Array();
            const tmpResult = $scope.ocrResult.userResultList[$scope.targetUserIdx];
            // 項目マスタのソート順に検索
            for(let i=0; i<$scope.itemMstList.length; i++) {
              for(let j=0; j<tmpResult.items.length; j++) {
                let tmpDataList = undefined;
                // 項目名がマスタと一致しない場合、次の項目を検索
                if($scope.itemMstList[i].name==tmpResult.items[j].name) {
                  tmpDataList = new Object();
                  tmpDataList.name = tmpResult.items[j].name;
                  tmpDataList.value = tmpResult.items[j].value;
                  tmpDataList.rect = tmpResult.items[j].rect;
                  tmpDataList.aliasMsg = $scope.itemMstList[i].aliasMsg;
                  tmpDataList.displayOrder = $scope.itemMstList[i].displayOrder;
                  tmpDataList.scrap = tmpResult.items[j].scrap;
                // 全ての項目に一致しなかった場合、項目マスタの情報を設定
                } else if((j+1)==tmpResult.items.length) {
                  tmpDataList = new Object();
                  tmpDataList.name = $scope.itemMstList[i].name;
                  tmpDataList.value = '';
                  tmpDataList.rect = [0,0,100,50]
                  tmpDataList.aliasMsg = $scope.itemMstList[i].aliasMsg;
                  tmpDataList.displayOrder = $scope.itemMstList[i].displayOrder;
                  tmpDataList.scrap = ($scope.itemMstList[i].type=="SCRAP");
                }
                // 項目情報が設定されなかった場合、次の項目を検索
                if(!tmpDataList) {
                  continue;
                }
                // 候補リストに含まれるか否かで設定するリストを変更
                if($scope.itemMstList[i].required || $scope.itemMstList[i].group!=0) {
                  // 候補リストに含まれる
                  $scope.candidateDataList.push(tmpDataList);
                  break;
                } else {
                  // 候補リスト以外
                  $scope.noCandidateDataList.push(tmpDataList);
                  break;
                }
              }
              // 候補者リストのソートに使用する値の初期設定
              $scope.sortIcon = new Array();
              $scope.upsort = new Array();
              for(let i=0; i<$scope.candidateDataList.length; i++) {
                $scope.sortIcon[i] = downSortIcon;
                $scope.upsort[i] = false;
              }
            }

            let loadingMsg = "";
            // 読込中メッセージを設定
            $translate(['loadingMsg.ocr-result'])
            .then(function (translations) {
              loadingMsg = translations['loadingMsg.ocr-result'];
            })
            .then(function() {
              // ローディング画面を表示
              $scope.startLoading(loadingMsg);
            });

            // 候補者一覧取得API
            $scope.executeApi('GET', '/api/jobs/' + $scope.ocrResult.jobId + '/candidates')
            .then(
              function() {
                // 成功時は候補者情報を設定
                const res = $scope.response.data;
                let tmpList = new Array();
                let ignoreIdxs = new Array();
                for(let i=0; i<res.length; i++) {
                  tmpList[i] = res[i].split(":");
                  // TODO ↓暫定対応：表示の際にフォームに登録されていない項目を削除する→登録時に削除するように修正予定
                  // 1行目（項目名）の場合、フォームに登録されている項目名と比較
                  if(i==0) {
                    angular.forEach(tmpList[i], function(name, j){
                      let k;
                      for(k=0; k<$scope.itemMstList.length; k++) {
                        if(tmpList[i][j]==$scope.itemMstList[k].name) {
                          break;
                        }
                      }
                      if(k==$scope.itemMstList.length) {
                        ignoreIdxs.push(j);
                      }
                    });
                  }
                  // 後ろからidxを削除
                  for(let j=ignoreIdxs.length-1; j>=0; j--) {
                    tmpList[i].splice(ignoreIdxs[j], 1);
                  }
                  // TODO ↑暫定対応
                }
                // 各項目の列幅%を計算して配列に格納
                let sum = 0;
                let i = 0;
                for (let i = 0; i < tmpList[1].length; i++) {
                  sum += parseInt(tmpList[1][i]);
                }
                let widthList = new Array();
                let widthTmp = 0;
                let widthSum = 0;
                const MAX_SIZE = 99.99; // セルの最大サイズ%
                const MIN_SIZE = 13; // セルの最小サイズ%
                for (let i = 0; i < tmpList[1].length; i++) {
                  if (i < tmpList[1].length - 1 ) {
                    widthTmp = parseInt(tmpList[1][i]) / sum * MAX_SIZE;
                      if (widthTmp < MIN_SIZE) {
                        widthTmp = MIN_SIZE;
                      }
                      widthSum += widthTmp;
                  } else {
                    widthTmp = MAX_SIZE - widthSum;
                  }
                  widthList[i] = widthTmp;
                }
                $scope.candidateList = tmpList;
                $scope.candidateWidths = widthList; // 各項目の列幅%の配列

                // ローディング画面を閉じる処理
                $scope.endLoading();
                // OCR結果編集を開く
                angular.element('#modal-ocr-result-edit').modal('show');

              // 失敗時はエラーメッセージを表示
              }, function() {
                // serverMsgAreaリセット
                $scope.serverMsgReset();
                $scope.server.error = true;
                
                // 権限のないフォームへのアクセス
                if($scope.httpStatus==403) {
                  $translate(['serverMsg.invalid-access'])
                  .then(function (translations) {
                    $scope.server.msg = translations['serverMsg.invalid-access'];
                  });
                } else {
                  $translate(['serverMsg.access-err'])
                  .then(function (translations) {
                    $scope.server.msg = translations['serverMsg.access-err'];
                  });
                }
                // ローディング画面を閉じる処理
                $scope.endLoading();
              }
            )
          }
        }
      )
    }

    // 候補一覧表示後に学生の情報を設定（初期表示対応）
    $scope.$on('repeatEnd', function() {
      for(let candidateIdx=0; candidateIdx<$scope.candidateList.length; candidateIdx++) {
        let itemIdx;
        itemLoop:
        for(itemIdx=0; itemIdx<$scope.candidateList[candidateIdx].length; itemIdx++) {
          for(let targetIdx=0; targetIdx<$scope.ocrResult.userResultList[$scope.targetUserIdx].items.length; targetIdx++) {
            const item = $scope.ocrResult.userResultList[$scope.targetUserIdx].items[targetIdx];
            if( (item.required || item.group!=0)
                 && $scope.candidateList[0][itemIdx]==item.name
                 && $scope.candidateList[candidateIdx][itemIdx]!=item.value) {

              break itemLoop;
            }
          }
        }
        if(itemIdx==$scope.candidateList[candidateIdx].length) {
          self.selectUser(candidateIdx);
          break;
        }
      }
      // 候補者情報以外のデータを削除する
      $scope.candidateList.splice(0,2);
    });

    // 候補一覧からのユーザ選択処理
    self.selectUser = function(idx) {
      angular.element('#modal-ocr-result-edit tbody tr').removeClass('info');
      angular.element('#id' + idx).addClass('info');

      for(let i=0; i<$scope.candidateDataList.length; i++) {
        $scope.candidateDataList[i].value = $scope.candidateList[idx][i];
      }
    };

    // 候補者リストに含まれる場合（「必須」または「組み合わせあり」）のみフィルタ
    $scope.candidateListFilter = function(value) {
      return value.required || value.group!=0;
    }

    // 修正処理
    self.resultEdit = function() {
      // セッションチェック(TODO:関数化したい)
      $scope.executeApi('GET', '/api/accounts/session')
      .then(function() {
        const res = $scope.response.data;
        if(typeof res.session === "undefined") {
          $window.location.href = '/views/login.html';
        } else {
          let reqData = new Object();
          reqData.confirm = false;
          if($scope.ocrResult.tags.indexOf($scope.loginId) == -1) {
            // tagsに編集者のログインIDを追加する
            $scope.ocrResult.tags.push($scope.loginId);
          }
          reqData.tags = $scope.ocrResult.tags;
          reqData.appData = '';
          reqData.result = new Array();
          // 1ユーザ分のデータしか修正しないため0を指定
          reqData.result[0] = new Object();
          reqData.result[0].sheetNo = $scope.ocrResult.userResultList[$scope.targetUserIdx].sheetNo;
          reqData.result[0].detectedItems = new Array();
          angular.forEach($scope.candidateDataList, function(candidateData) {
            if(!candidateData.scrap) {
              let correction = new Object();
              correction.name = candidateData.name;
              correction.value = candidateData.value;
              correction.rect = candidateData.rect;
              reqData.result[0].detectedItems.push(correction);
            }
          });
          angular.forEach($scope.noCandidateDataList, function(noCandidateData) {
            if(!noCandidateData.scrap) {
              let correction = new Object();
              correction.name = noCandidateData.name;
              correction.value = noCandidateData.value;
              correction.rect = noCandidateData.rect;
              reqData.result[0].detectedItems.push(correction);
            }
          });

          let loadingMsg = "";
          // 読込中メッセージを設定
          $translate(['loadingMsg.ocr-result-fix'])
          .then(function (translations) {
            loadingMsg = translations['loadingMsg.ocr-result-fix'];
          })
          .then(function() {
            // modalが2重で表示されることにより、bodyのpadding-rightが大きくなる不具合の対策
            setTimeout(function() {$scope.startLoading(loadingMsg);}, 350);
          });

          // 結果修正API
          $scope.executeApi('PUT', 'api/ocr/' + $scope.ocrResult.jobId + '/recognition', reqData)
          .then(
            // 成功時は実行一覧画面に遷移
            function() {
              // serverMsgAreaリセット
              $scope.serverMsgReset();

              // 修正した内容をブラウザ側のデータに反映
              let targetUserResult = $scope.ocrResult.userResultList[$scope.targetUserIdx];
              // ステータスを初期化
              targetUserResult.status = '';
              targetUserResult.statusMsg = undefined;

              let tmpPdfNameList = new Array();
              angular.forEach(targetUserResult.items, function(userItem) {
                // 一致する項目の値を設定
                angular.forEach($scope.candidateDataList, function(candidateData) {
                  if(userItem.name == candidateData.name) {
                    userItem.value = candidateData.value;
                  }
                });
                angular.forEach($scope.noCandidateDataList, function(noCandidateData) {
                  if(userItem.name == noCandidateData.name) {
                    userItem.value = noCandidateData.value;
                  }
                });
                // PDF名作成用の文字列を設定（scraps以外で[必須]または[組み合わせ（一意）]のvalue)
                if(!userItem.scrap && (userItem.required || userItem.group!=0)) {
                  let tmpPdfName = new Object();
                  tmpPdfName.displayOrder = userItem.displayOrder;
                  tmpPdfName.value = userItem.value;
                  tmpPdfNameList.push(tmpPdfName);
                }
              });
              // DL時のpdfファイル名生成（ソート順に設定）
              let pdfName = '';
              tmpPdfNameList.sort(function(a,b){
                if( a.displayOrder < b.displayOrder ) {
                  return -1;
                }
                if( a.displayOrder > b.displayOrder ) {
                  return 1;
                }
                return 0;
              });
              for(let i=0; i<tmpPdfNameList.length; i++) {
                pdfName += tmpPdfNameList[i].value + '_';
              }
              // pdfファイル名を再設定
              targetUserResult.pdf.name = pdfName.slice(0,-1) + targetUserResult.pdf.name.slice(targetUserResult.pdf.name.indexOf('.'));

              // 再度チェック処理を行う
              self.checkListErr();

              $scope.server.info = true;
              $translate(['serverMsg.ocr-edit'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.ocr-edit'];
              })
              .then(function() {
                // ローディング画面を閉じる処理
                setTimeout(function() {$scope.endLoading();}, 350);
              });

            // 失敗時はエラーメッセージを表示
            }, function() {
              // serverMsgAreaリセット
              $scope.serverMsgReset();

              $scope.server.error = true;
              if($scope.httpStatus==403) {
                $translate(['serverMsg.invalid-access'])
                .then(function (translations) {
                  $scope.server.msg = translations['serverMsg.invalid-access'];
                });
              } else {
                $translate(['serverMsg.access-err'])
                .then(function (translations) {
                  $scope.server.msg = translations['serverMsg.access-err'];
                });
              }
              // ローディング画面を閉じる処理
              setTimeout(function() {$scope.endLoading();}, 350);
              }
            )
          }
        }
      )
    }

    // 確認完了処理
    self.resultFix = function() {

      // セッションチェック(TODO:関数化したい)
      $scope.executeApi('GET', '/api/accounts/session')
      .then(function() {
        const res = $scope.response.data;
        if(typeof res.session === "undefined") {
          $window.location.href = '/views/login.html';
        } else {
          let reqData = new Object();
          if($scope.ocrResult.tags.indexOf($scope.loginId) == -1) {
            // tagsに編集者のログインIDを追加する
            $scope.ocrResult.tags.push($scope.loginId);
          }
          reqData.tags = $scope.ocrResult.tags;
          reqData.appData = '';
          reqData.result = new Array();
          reqData.confirm = true;
          // 得点入力チェック用に認識結果を追加
          for(let i=0; i<$scope.ocrResult.userResultList.length; i++) {
              reqData.result[i] = new Object();
              reqData.result[i].detectedItems = new Array();
              angular.forEach($scope.ocrResult.userResultList[i].items, function(detectedItem) {
                  if(!detectedItem.scrap) {
                      let correction = new Object();
                      correction.name = detectedItem.name;
                      correction.value = detectedItem.value;
                      reqData.result[i].detectedItems.push(correction);
                  }
              });
          }
          let loadingMsg = "";
          // 読込中メッセージを設定
          $translate(['loadingMsg.ocr-result-confirm'])
          .then(function (translations) {
            loadingMsg = translations['loadingMsg.ocr-result-confirm'];
          })
          .then(function() {
            // ローディング画面を表示
            $scope.startLoading(loadingMsg);
          });

          // 確認完了API
          $scope.executeApi('PUT', 'api/ocr/' + $scope.ocrResult.jobId + '/recognition', reqData)
          .then(
            // 成功時は実行一覧画面に遷移
            function() {
              // serverMsgAreaリセット
              $scope.serverMsgReset();

              $scope.server.info = true;
              let ocrFixData = {
                jobName: $scope.ocrResult.jobName,
                type: ''
              };
              $translate(['ocr.result.update'])
              .then(function (translations) {
                ocrFixData.type = translations['ocr.result.update'];
              })
              .then(function() {
                  $translate(['serverMsg.ocr-fix'], ocrFixData)
                  .then(function (translations) {
                    $scope.server.msg = translations['serverMsg.ocr-fix'];
                  });
              })
              .then(function() {
                // ローディング画面を閉じる処理
                $scope.endLoading();
                // 画面遷移処理
                $state.go('ocr-jobs');
              });

            // 失敗時はエラーメッセージを表示
            }, function() {
                // serverMsgAreaリセット
                $scope.serverMsgReset();

              $scope.server.error = true;
              if($scope.httpStatus==403) {
                $translate(['serverMsg.invalid-access'])
                .then(function (translations) {
                  $scope.server.msg = translations['serverMsg.invalid-access'];
                });
              // 不正な得点が入力されている
              } else if($scope.httpStatus==400 && $scope.response.data.ERROR_CODE=='SCORE_INCORRECT') {
                $translate(['serverMsg.score-incorrect'])
                .then(function (translations) {
                  $scope.server.msg = translations['serverMsg.score-incorrect'];
                });
              } else {
                $translate(['serverMsg.access-err'])
                .then(function (translations) {
                  $scope.server.msg = translations['serverMsg.access-err'];
                });
              }
              // ローディング画面を閉じる処理
              $scope.endLoading();
              }
            )
          }
        }
      )
    }

    // 候補者リストのソート処理
    self.candidateListSorter = function(idx, upsort) {
      let tmpArray = new Array();
      let length = $scope.candidateList.length;
      // ソートアイコンを設定
      if(upsort) {
        $scope.sortIcon[idx] = upSortIcon;
        $scope.upsort[idx] = true;
      } else {
        $scope.sortIcon[idx] = downSortIcon;
        $scope.upsort[idx] = false;
      }
      for(let i=0; i<length; i++) {
        let targetIdx = 0;
        for(let j=1; j<$scope.candidateList.length; j++) {
          let a = $scope.candidateList[targetIdx][idx];
          let b = $scope.candidateList[j][idx];
          // intに変換できる値は変換してから比較する
          // 変換できない場合は文字列のまま比較する
          if(!isNaN(a) && !isNaN(b)) {
            a = parseInt($scope.candidateList[targetIdx][idx]);
            b = parseInt($scope.candidateList[j][idx]);
          }
          if(upsort) {
            if(a > b) {
              targetIdx = j;
            }
          } else {
            if(a < b) {
              targetIdx = j;
            }
          }
        }
        tmpArray.push($scope.candidateList[targetIdx]);
        $scope.candidateList.splice(targetIdx, 1);
      }
      // ソートした配列で上書きする
      $scope.candidateList = tmpArray;
    };

    // ページから離れた場合は再読み込み監視を削除する。
    // TODO 画面遷移の監視が複数回行われるため修正
    $transitions.onStart({from: 'ocr-result-edit'}, function(trans) {
      $window.removeEventListener('beforeunload', onBeforeunloadHandler, false);
    });

  }
]);
