app.controller('LoginCtrl', ['$scope', '$window', '$location',
  function($scope, $window, $location) {

    // '/'を空文字に置換する('/'が複数あった場合の対策)
    let pathName = location.pathname.replace(/[\/]/g, '');

    // ログインチェック
    if (pathName == 'login-error') {
      $scope.loginErr = true;
    } else {
      $scope.loginErr = false;
    }

    // 正しいURL以外からのアクセスがあった場合に正しいURLに遷移させる
    if((pathName != '') && (pathName != 'login-error')) {
      let correctUrl = $location.protocol() + '://' + $location.host() + ':' + $location.port() + '/';
      $window.location.href = correctUrl;
    }
}]);

