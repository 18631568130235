let app = angular.module('app', ['ui.bootstrap', 'ui.router', 'pascalprecht.translate', 'ngCookies']);

app.controller('AppCtrl', ['$rootScope', '$scope', '$http', '$window', '$transitions', '$cookies', '$state', '$controller', '$location', '$translate', 'ServerMsgService',
  function($rootScope, $scope, $http, $window, $transitions, $cookies, $state, $controller, $location, $translate, ServerMsgService) {
    // CommonCtrlの継承
    angular.extend(this, $controller('CommonCtrl', {$scope: $scope, $http: $http}));

    // factoryからデータ共有
    $scope.server = ServerMsgService;

    const self = this;

    // ｢ユーザー新規登録画面｣から遷移してきたときの対策としてナビゲーションバーを非表示にしておく
    $scope.isNavShow = false;

    // サーバメッセージ表示リセット
    $scope.serverMsgReset = function() {
      $scope.server.info = false;
      $scope.server.warn = false;
      $scope.server.error = false;
      $scope.server.msg = '';
    }

    // ユーザー登録が完了していた場合にメッセージ表示
    if($location.search()["userCreate"]) {
      // serverMsgAreaリセット
      $scope.serverMsgReset();

      $scope.server.info = true;
      $translate(['serverMsg.user-create'])
      .then(function (translations) {
        $scope.server.msg = translations['serverMsg.user-create'];
      });
    }

    // ユーザー名を取得するAPIを実行する
    // TODO cookieにユーザー名をセットしたい
    $scope.executeApi('GET', '/api/accounts/my')
    .then(
      // アクセス成功
      function() {
        let res = $scope.response.data;
        $scope.username = res.name;
        // TODO ログインIDを保持する別の方法を検討する
        $scope.loginId = res.loginId;
        $scope.isAdmin = res.isAdmin;
      }
    )
    // ユーザー新規登録画面のURL書き換え対策
    .then(
      function() {
        if(document.referrer == '' && $scope.username == '') {
          $window.location.href = '/user-create.html';
        } else {
          // ナビゲーションバーを表示する
          $scope.isNavShow = true;
        }
      }
    );

    // 現在選択中のタブを取得する
    $transitions.onStart({}, function(trans) {
      let toState = trans.to();
      self.currentTab = {
        'ocr' : false,
        'form' : false,
        'manage' : false,
        'setting' : false
      };
      // 遷移時に画面幅によってグローバルメニューのレイアウトを変更
      if(typeof toState.data !== "undefined") {
        if (!$window.matchMedia('(min-width:768px)').matches) {
          $scope.collapsedSize = true;
          self.currentTab[toState.data.tab] = false;
        } else {
          $scope.collapsedSize = false;
          self.currentTab[toState.data.tab] = true;
        };
      }
    });

    // ナビゲージョンバーの開閉
    self.navCollapsed = true;
    const menuOpen = {
      'ocr' : false,
      'form' : false,
      'manage' : false,
      'setting' : false
    };
    self.openMenu = function(menu) {
      menuOpen[menu] = true;
    };
    self.closeMenu = function(menu) {
      menuOpen[menu] = false;
    };
    self.isOpenMenu = function(menu) {
      return menuOpen[menu];
    };
    self.changeNav = function() {
      // serverMsgAreaリセット
      $scope.serverMsgReset();
      // 開閉切替
      self.navCollapsed = !self.navCollapsed;
    };
    // ナビゲーションバーを閉じる処理
    self.closeNav = function() {
      self.navCollapsed = true;
    };

    // キャンセル実行処理
    self.cancel = function() {
      // serverMsgAreaリセット
      $scope.serverMsgReset();
      // ポップアップを閉じる
      closeModal();
    };

    // ログアウト
    // TODO クッキーの削除を「CookieController」でできないか検討する
    self.logout = function() {
      $cookies.remove('username');
      $http({
        method : 'POST',
        url : '/api/logout'
      }).then(function() {
        sessionStorage.clear();
        $window.location.href = '/';
      });
    };

    // 画面幅の変更を監視し、画面幅によってグローバルメニューのレイアウトを変更
    angular.element($window).on('resize', function(){
      $scope.$apply(function () {
        if(typeof $state.current.data !== "undefined") {
          if (!$window.matchMedia('(min-width:768px)').matches) {
            $scope.collapsedSize = true;
            self.currentTab[$state.current.data.tab] = false;
          } else {
            $scope.collapsedSize = false;
            self.currentTab[$state.current.data.tab] = true;
          }
        }
      });
    });

    // 個人設定から遷移したときを監視し、ユーザー名を更新する
    $transitions.onStart({to: 'ocr-jobs', from: 'user'}, function(trans) {
      $scope.executeApi('GET', '/api/accounts/my')
      .then(
        // アクセス成功
        function() {
          let res = $scope.response.data;
          $scope.username = res.name;
        }
      );
    });

}]);

//クッキーを制御するコントローラー
app.controller('CookieController',['$scope', '$cookies', '$window',  function($scope, $cookies, $window) {
  const self = this;

  // keyとvalueをクッキーに設定
  self.putCookie = function(key, value) {
    $cookies.put(key, value);
  };

  // keyに対応するvalueを取得
  self.getCookie = function(key) {
    return $cookies.get(key);
  };
}]);
