let app = angular.module('app');

app.controller('CommonCtrl', ['$scope', '$http', '$q',
  function($scope, $http, $q) {

    // ローディング画面表示処理
    $scope.startLoading = function(loadingMsg) {
      // 読込中メッセージを設定
      $scope.loadingMsg = loadingMsg;
      angular.element('#modal-loading').modal({ backdrop: 'static', keyboard: false });
      angular.element('#modal-loading').modal('show');
    };

    // ローディング画面を閉じる処理
    $scope.endLoading = function() {
      closeModal();
      angular.element('#modal-loading').modal('hide');
    };

    // APIの実行
    $scope.executeApi = function(p_method, p_url, p_data) {
      let d = $q.defer();

      $http({
        method : p_method,
        url : p_url,
        data : p_data,
      })
      .then(
        // 成功時
        function onSuccess(response){
          $scope.httpStatus = response.status;
          $scope.response = response;
          d.resolve();
        // 失敗時
        },function onError(response){
          $scope.httpStatus = response.status;
          $scope.response = response;
          d.reject();
        }
      );
      return d.promise;
    };

    // DL処理
    $scope.createDlLink = function(data, type, fileName) {
      // Base64からバイナリに変換
      var bin = atob(data);
      var buffer = new Uint8Array(bin.length);
      // バイナリをUint8Arrayに変換
      for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      // Uint8Arrayを元にBlobを作成
      var blob = new Blob([buffer.buffer], {'type': type});
      // ブラウザがIEかどうかを判定
      if (window.navigator.msSaveOrOpenBlob) {
        // 保存ダイアログを表示
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        // ファイルDL用リンク作成
        let a = document.createElement('A');
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    };

    // DL処理専用のAPI実行
    $scope.executeDLApi = function(p_url, fileName) {
      let d = $q.defer();

      $http({
        method : 'GET',
        url : p_url,
      })
      .then(
        // 成功時
        function onSuccess(response){
          // レスポンスからファイル作成
          const res = response.data;
          // DL処理
          $scope.createDlLink(res.data, res.type, fileName);
          d.resolve();
        // 失敗時
        },function onError(response){
          $scope.response = response;
          d.reject();
        }
      );
      return d.promise;
    };

}]);

// サーバメッセージエリア
app.factory('ServerMsgService', function() {
  let infoFlg = false;
  let warnFlg = false;
  let errorFlg = false;
  let msgTxt = '';

  return {
    info: infoFlg,
    warn: warnFlg,
    error: errorFlg,
    msg: msgTxt,
  };
});
app.controller('ServerMsgCtrl', ['$scope', 'ServerMsgService',
  function($scope, ServerMsgService) {
    const self = this;

    // 初期値設定
    $scope.server = ServerMsgService;
}]);

// stepメニューエリア
app.controller('StepMenuCtrl', ['$scope', '$transitions', '$state', 'ServerMsgService',
function($scope, $transitions, $state, ServerMsgService) {
  const self = this;

  // factoryからデータ共有
  $scope.server = ServerMsgService;

  // 遷移を監視
  $transitions.onStart({}, function(trans) {
    const toState = trans.to();
    // 現在選択中のタブが「個人設定」、「管理機能」の場合、stepメニューを表示しない
    if(typeof toState.data !== "undefined" &&
          (toState.data.tab === 'setting' || toState.data.tab === 'manage') ) {
      $scope.noStepMenu = true;
    } else {
      $scope.noStepMenu = false;

      // 遷移先が「登録用紙編集」または「読み取り結果確認」の場合、それぞれのstepを選択状態とする
      $scope.formsActive = false;
      $scope.ocrJobsActive = false;
      if(toState.name === 'form-edit') {
        $scope.formsActive = true;
      } else if(toState.name === 'ocr-result-edit') {
        $scope.ocrJobsActive = true;
      }
    }
  });

  // stepボタンを押下したときの画面遷移をチェックする
  self.checkTransition = function(event, targetStep) {
    // stepごとに特定の画面から遷移する場合は遷移を中止する
    if((targetStep === 'step2' && $state.current.name === 'form-edit')
        || (targetStep === 'step4' && $state.current.name === 'ocr-result-edit')) {
      event.preventDefault();
    } else {
      // serverMsgAreaリセット
      self.serverMsgReset();
    }
  }

  // serverMsgAreaリセット
  self.serverMsgReset = function() {
    $scope.serverMsgReset();
  }

}]);


// ファイル選択処理用ディレクティブ
app.directive('fileModel', [ '$parse',
  function($parse){

    return{
      restrict: 'A',
      link: function(scope,element,attrs){
        var model = $parse(attrs.fileModel);
        element.bind('change',function(){
          scope.$apply(function(){
            model.assign(scope,element[0].files[0]);
          });
        });
      }
    };
}]);

//ファイル選択処理用ディレクティブ（マルチ対応）
app.directive('multiFileModel', [ '$parse',
function($parse){

 return{
   restrict: 'A',
   link: function(scope,element,attrs){
     var model = $parse(attrs.multiFileModel);
     element.bind('change',function(){
       scope.$apply(function(){
         model.assign(scope,element[0].files);
       });
     });
   }
 };
}]);

//ng-repaet完了後に処理するディレクティブ
app.directive("repeatEndEvent", [ '$timeout',
function($timeout){
  return function(scope, element, attrs){
    if (scope.$last){
      $timeout(function(){
        scope.$emit("repeatEnd");
      });
    }
  }
}]);

// ドラッグ＆ドロップを監視するディレクティブ
app.directive('fileDropArea',
function(){
  return{
    restrict: 'A',
    scope:{onDropFile: '&'},
    link: function(scope,element,attrs){

      // ドラッグ中に要素上にカーソルが乗ったときの処理
      let processDragOverOrEnter = function(event){
        event.stopPropagation();
        event.preventDefault();
        //背景色変更
        element.css('background-color','#aaa');
      };

      // ドロップしたときの処理
      let processDrop = function(event){
        event.stopPropagation();
        event.preventDefault();
        // 背景色変更
        element.css('background-color',"#eee");
        // ディレクティブのスコープに値を設定
        scope.onDropFile({file: event.originalEvent.dataTransfer.files[0]});
      };

      // ドラッグ中に要素上からカーソルが離れたときの処理
      let processDragLeave = function(event){
        //背景色を戻す
        element.css('background-color',"#eee");
      };

      // 関数にイベントをバインド
      element.bind('dragover',processDragOverOrEnter);
      element.bind('dragenter',processDragOverOrEnter);
      element.bind('drop',processDrop);
      element.bind('dragleave',processDragLeave);
    }
  }
});

// ドラッグ＆ドロップを監視するディレクティブ（マルチ対応）
app.directive('multiFileDropArea',
function(){
  return{
    restrict: 'A',
    scope:{onMultiDropFile: '&'},
    link: function(scope,element,attrs){

      // ドラッグ中に要素上にカーソルが乗ったときの処理
      let processDragOverOrEnter = function(event){
        event.stopPropagation();
        event.preventDefault();
        //背景色変更
        element.css('background-color','#aaa');
      };

      // ドロップしたときの処理
      let processDrop = function(event){
        event.stopPropagation();
        event.preventDefault();
        // 背景色変更
        element.css('background-color',"#eee");
        // ディレクティブのスコープに値を設定
        scope.onMultiDropFile({files: event.originalEvent.dataTransfer.files});
      };

      // ドラッグ中に要素上からカーソルが離れたときの処理
      let processDragLeave = function(event){
        //背景色を戻す
        element.css('background-color',"#eee");
      };

      // 関数にイベントをバインド
      element.bind('dragover',processDragOverOrEnter);
      element.bind('dragenter',processDragOverOrEnter);
      element.bind('drop',processDrop);
      element.bind('dragleave',processDragLeave);
    }
  }
});


// ポップアップを閉じる処理
function closeModal() {
  angular.element('body').removeClass('modal-open');
  angular.element('.modal-backdrop').remove();
};

// 日付の書式フォーマットを設定する処理
// format例) "yyyy/MM/dd hh:mm:ss" → "2017/11/13 13:48:11"
function formatDate (date, format) {
  format = format.replace(/yyyy/g, date.getFullYear());
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
  return format;
};
