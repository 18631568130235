angular.module('app').config(['$stateProvider', function($stateProvider) {

  $stateProvider.state({
    name : 'ocr-jobs',
    url : '/ocr-jobs',
    templateUrl : '/views/ocr-jobs.html',
    data : {
      tab : "ocr"
    }
  });

  $stateProvider.state({
    name : 'ocr-job-create',
    url : '/ocr-jobs/new-job-create',
    templateUrl : '/views/ocr-job-create.html',
    data : {
      tab : "ocr"
    }
  });

  $stateProvider.state({
    name : 'ocr-result-edit',
    url : '/ocr-jobs/job-result',
    templateUrl : '/views/ocr-result-edit.html',
    data : {
      tab : "ocr"
    }
  });

  $stateProvider.state({
    name : 'forms',
    url : '/forms',
    templateUrl : '/views/forms.html',
    data : {
      tab : "form"
    }
  });

  $stateProvider.state({
    name : 'form-create',
    url : '/forms/new',
    templateUrl : '/views/form-create.html',
    data : {
      tab : "form"
    }
  });

  $stateProvider.state({
    name : 'form-edit',
    url : '/forms/edit',
    templateUrl : '/views/form-edit.html',
    data : {
      tab : "form"
    }
  });

  $stateProvider.state({
    name : 'user',
    url : '/users/my',
    templateUrl : '/views/user-update.html',
    data : {
      tab : "setting"
    }
  });

  $stateProvider.state({
    name : 'user-confirm',
    url : '/users/confirm',
    templateUrl : '/views/user-update-confirm.html',
    data : {
      tab : "setting"
    }
  });

  $stateProvider.state({
    name : 'manage-items',
    url : '/manage/items',
    templateUrl : '/views/manage/items.html',
    data : {
      tab : "manage"
    }
  });

  $stateProvider.state({
    name : 'manage-users',
    url : '/manage/users',
    templateUrl : '/views/manage/users.html',
    data : {
      tab : "manage"
    }
  });

}]);
