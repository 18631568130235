let app = angular.module('app');

app.controller('FormCreateCtrl', ['$scope', '$transitions', 'FormService',
  function($scope, $transitions, FormService) {
    // 初期値設定
    $scope.page = FormService;

    // フォーム新規登録画面を離れたときを監視し、factoryを初期化する
    // TODO 画面遷移の監視が複数回行われるため修正
    $transitions.onStart({from: 'form-create'}, function(trans) {
      $scope.page.fileSelectPage = true;
      $scope.page.formName = "";
      $scope.page.formDescription = "";
      $scope.page.isPublicForm = true;
      $scope.page.formNoBody = true;
      $scope.page.formClassFB = undefined;
      $scope.page.formClassFA = undefined;
      $scope.page.formClassBB = undefined;
      $scope.page.formClassBA = undefined;
      $scope.page.fileNameFB = "";
      $scope.page.fileNameFA = "";
      $scope.page.fileNameBB = "";
      $scope.page.fileNameBA = "";
      $scope.page.imgSrcFB = "img/preview.png";
      $scope.page.imgSrcFA = "img/preview.png";
      $scope.page.imgSrcBB = "img/preview.png";
      $scope.page.imgSrcBA = "img/preview.png";
      $scope.page.formatFB = "";
      $scope.page.formatFA = "";
      $scope.page.formatBB = "";
      $scope.page.formatBA = "";
      $scope.page.imgDataFB = "";
      $scope.page.imgDataFA = "";
      $scope.page.imgDataBB = "";
      $scope.page.imgDataBA = "";
      $scope.page.coverForms = undefined;
      $scope.page.contentForms = undefined;
      $scope.page.showRotateBtn = false;
    });

}]);

// フォーム新規登録/確認画面で共通して使う変数 TODO 画面遷移の前後でここ以外でデータを保持する方法はないか検討
app.factory('FormService', function() {
  return {
    fileSelectPage: true,
    formName: "",
    formDescription: "",
    isPublicForm: true,
    tags: undefined,
    formNoBody: true,
    formClassFB: undefined,
    formClassFA: undefined,
    formClassBB: undefined,
    formClassBA: undefined,
    fileNameFB: "",
    fileNameFA: "",
    fileNameBB: "",
    fileNameBA: "",
    imgSrcFB: "img/preview.png",
    imgSrcFA: "img/preview.png",
    imgSrcBB: "img/preview.png",
    imgSrcBA: "img/preview.png",
    formatFB: "",
    formatFA: "",
    formatBA: "",
    formatBB: "",
    imgDataFB: "",
    imgDataFA: "",
    imgDataBA: "",
    imgDataBB: "",
    coverForms: undefined,
    contentForms: undefined,
    showRotateBtn: false,
  };
});

// フォームリストで選択されたフォームの情報を共有
app.factory('FormListService', function() {
  return {
    formInfo: new Object(),
    formImgF: "",
    firnImgB: "",
    formNoBody: true,
  };
});

//フォーム新規登録（ファイル選択画面）
app.controller('FormFileSelectCtrl', ['$scope', '$http', '$controller', '$translate', '$window', 'FormService', 'ServerMsgService',
  function($scope, $http, $controller, $translate, $window, FormService, ServerMsgService) {
  const self = this;

  // factoryからデータ共有
  $scope.page = FormService;
  $scope.server = ServerMsgService;

  // 初期値設定
  let defimg = 'img/preview.png';
  $scope.formName = $scope.page.formName;
  $scope.formDescription = $scope.page.formDescription;
  if($scope.formDescription != undefined) {
    $scope.formDescription = $scope.formDescription.replace(/\\n/g, '\n');
  }
  $scope.isPublicForm = $scope.page.isPublicForm;
  $scope.errFB = false;
  $scope.errFA = false;
  $scope.errBB = false;
  $scope.errBA = false;
  $scope.errReqFB = false;
  $scope.errReqFA = false;
  $scope.errFormName = false;
  $scope.classFB = $scope.page.formClassFB;
  $scope.classFA = $scope.page.formClassFA;
  $scope.classBB = $scope.page.formClassBB;
  $scope.classBA = $scope.page.formClassBA;
  $scope.prevImgSrcFB = $scope.page.imgSrcFB;
  $scope.prevImgSrcFA = $scope.page.imgSrcFA;
  $scope.prevImgSrcBB = $scope.page.imgSrcBB;
  $scope.prevImgSrcBA = $scope.page.imgSrcBA;
  $scope.prevFileNameFB = $scope.page.fileNameFB;
  $scope.prevFileNameFA = $scope.page.fileNameFA;
  $scope.prevFileNameBB = $scope.page.fileNameBB;
  $scope.prevFileNameBA = $scope.page.fileNameBA;
  $scope.formatFB = $scope.page.formatFB;
  $scope.formatFA = $scope.page.formatFA;
  $scope.formatBA = $scope.page.formatBA;
  $scope.formatBB = $scope.page.formatBB;
  $scope.imgDataFB = $scope.page.imgDataFB;
  $scope.imgDataFA = $scope.page.imgDataFA;
  $scope.imgDataBA = $scope.page.imgDataBA;
  $scope.imgDataBB = $scope.page.imgDataBB;
  $scope.showRotateBtn = $scope.page.showRotateBtn;

  // ファイル選択時に、ファイル読み込み→png変換→表示を実行
  // 各ファイルごとに処理を分岐 TODO 処理をまとめたい！！最低限関数化！！！！
  // 表紙（記入前）：Front Before///////////////////////////////////////
  $scope.$watch("fbfile",function(file){
    self.selectFbfile(file);
  });

  self.selectFbfile = function(file) {
    // セッションチェック(TODO:関数化したい)
    $scope.executeApi('GET', '/api/accounts/session')
    .then(function() {
      const res = $scope.response.data;
      if(typeof res.session === "undefined") {
        $window.location.href = '/views/login.html';
      } else {
        // 初期表示時は処理しない
        if(!file){
          return;
        }

        // ファイル取得時の処理
        let reader = new FileReader();
        reader.onload = function(){
          $scope.$apply(function(){
            // ファイル名表示
            $scope.prevFileNameFB = file.name;

            // ファイルのバイナリデータと拡張子取得
            let dataStartIndex = reader.result.indexOf(',') + 1;
            let imgData = reader.result.slice(dataStartIndex);
            let extPeriodIndex = file.name.indexOf('.');
            let format = file.name.slice(extPeriodIndex + 1).toLowerCase();

            // 画像変換API
            let reqData = {
              format: format,
              data: imgData,
            };

            let loadingMsg = "";
            // 読込中メッセージを設定
            $translate(['loadingMsg.file-select'])
            .then(function (translations) {
              loadingMsg = translations['loadingMsg.file-select'];
            })
            .then(function() {
              // ローディング画面を表示
              $scope.startLoading(loadingMsg);
            });

            $scope.executeApi('POST', '/api/forms/thumbnail', reqData)
            .then(
              function() {
                // 成功時はファイルを表示
                let res = $scope.response.data;
                $scope.classFB = "COVER";
                $scope.prevImgSrcFB = 'data:image/'+ res.format + ';base64,' + res.data;
                $scope.formatFB = res.format;
                $scope.imgDataFB = res.data;
                $scope.errFB = false;
                $scope.errReqFB = false;
                $scope.showRotateBtn = true;
                // ローディング画面を閉じる処理
                $scope.endLoading();

              }, function() {
                // 失敗時はエラーメッセージを表示
                $scope.classFB = undefined;
                $scope.prevImgSrcFB = defimg;
                $scope.formatFB = "";
                $scope.imgDataFB = "";
                $scope.errDataFB = { errFileName : file.name };
                $scope.errFB = true;
                $scope.errReqFB = false;
                $scope.showRotateBtn = false;
                // ローディング画面を閉じる処理
                $scope.endLoading();
              }
            );
          });
          // タブを切り替える
          document.getElementById('tab-front-b').click();
        };
        // ファイルURL読み込み
        reader.readAsDataURL(file)
      }
    })
  };

  // 表紙（記入後）：Front After///////////////////////////////////////
  $scope.$watch("fafile",function(file){
    self.selectFafile(file);
  });

  self.selectFafile = function(file) {
    // セッションチェック(TODO:関数化したい)
    $scope.executeApi('GET', '/api/accounts/session')
    .then(function() {
      const res = $scope.response.data;
      if(typeof res.session === "undefined") {
        $window.location.href = '/views/login.html';
      } else {
        // 初期表示時は処理しない
        if(!file){
          return;
        }

        // ファイル取得時の処理
        let reader = new FileReader();
        reader.onload = function(){
          $scope.$apply(function(){
            // ファイル名表示
            $scope.prevFileNameFA = file.name;

            // ファイルのバイナリデータと拡張子取得
            let dataStartIndex = reader.result.indexOf(',') + 1;
            let imgData = reader.result.slice(dataStartIndex);
            let extPeriodIndex = file.name.indexOf('.');
            let format = file.name.slice(extPeriodIndex + 1).toLowerCase();

            // 画像変換API
            let reqData = {
              format: format,
              data: imgData,
            };

            let loadingMsg = "";
            // 読込中メッセージを設定
            $translate(['loadingMsg.file-select'])
            .then(function (translations) {
              loadingMsg = translations['loadingMsg.file-select'];
            })
            .then(function() {
              // ローディング画面を表示
              $scope.startLoading(loadingMsg);
            });

            $scope.executeApi('POST', '/api/forms/thumbnail', reqData)
            .then(
              function() {
                // 成功時はファイルを表示
                let res = $scope.response.data;
                $scope.classFA = "COVER_HANDWRITTEN";
                $scope.prevImgSrcFA = 'data:image/'+ res.format + ';base64,' + res.data;
                $scope.formatFA = res.format;
                $scope.imgDataFA = res.data;
                $scope.errFA = false;
                $scope.errReqFA = false;
                $scope.showRotateBtn = true;
                // ローディング画面を閉じる処理
                $scope.endLoading();

              }, function() {
                // 失敗時はエラーメッセージを表示
                $scope.classFA = undefined;
                $scope.prevImgSrcFA = defimg;
                $scope.formatFA = "";
                $scope.imgDataFA = "";
                $scope.errDataFA = { errFileName : file.name };
                $scope.errFA = true;
                $scope.errReqFA = false;
                $scope.showRotateBtn = false;
                // ローディング画面を閉じる処理
                $scope.endLoading();
              }
            );
          });
          // タブを切り替える
          document.getElementById('tab-front-a').click();
        };
        // ファイルURL読み込み
        reader.readAsDataURL(file)
      }
    })
  };

  // 本文（記入前）：Body Before///////////////////////////////////////
  $scope.$watch("bbfile",function(file){
    self.selectBbfile(file);
  });

  self.selectBbfile = function(file) {

    // セッションチェック(TODO:関数化したい)
    $scope.executeApi('GET', '/api/accounts/session')
    .then(function() {
      const res = $scope.response.data;
      if(typeof res.session === "undefined") {
        $window.location.href = '/views/login.html';
      } else {
        // 初期表示時は処理しない
        if(!file){
          return;
        }

        // ファイル取得時の処理
        let reader = new FileReader();
        reader.onload = function(){
          $scope.$apply(function(){
            // ファイル名表示
            $scope.prevFileNameBB = file.name;

            // ファイルのバイナリデータと拡張子取得
            let dataStartIndex = reader.result.indexOf(',') + 1;
            let imgData = reader.result.slice(dataStartIndex);
            let extPeriodIndex = file.name.indexOf('.');
            let format = file.name.slice(extPeriodIndex + 1).toLowerCase();

            // 画像変換API
            let reqData = {
              format: format,
              data: imgData,
            };

            let loadingMsg = "";
            // 読込中メッセージを設定
            $translate(['loadingMsg.file-select'])
            .then(function (translations) {
              loadingMsg = translations['loadingMsg.file-select'];
            })
            .then(function() {
              // ローディング画面を表示
              $scope.startLoading(loadingMsg);
            });

            $scope.executeApi('POST', '/api/forms/thumbnail', reqData)
            .then(
              function() {
                // 成功時はファイルを表示
                let res = $scope.response.data;
                $scope.classBB = "CONTENT";
                $scope.prevImgSrcBB = 'data:image/'+ res.format + ';base64,' + res.data;
                $scope.formatBB = res.format;
                $scope.imgDataBB = res.data;
                $scope.errBB = false;
                $scope.showRotateBtn = true;
                // ローディング画面を閉じる処理
                $scope.endLoading();

              }, function() {
                // 失敗時はエラーメッセージを表示
                $scope.classBB = undefined;
                $scope.prevImgSrcBB = defimg;
                $scope.formatBB = "";
                $scope.imgDataBB = "";
                $scope.errDataBB = { errFileName : file.name };
                $scope.errBB = true;
                $scope.showRotateBtn = false;
                // ローディング画面を閉じる処理
                $scope.endLoading();
              }
            );
          });
          // タブを切り替える
          document.getElementById('tab-body-b').click();
        };
        // ファイルURL読み込み
        reader.readAsDataURL(file)
      }
    })
  };

  // 本文（記入後）：Body After///////////////////////////////////////
  $scope.$watch("bafile",function(file){
    self.selectBafile(file);
  });

  self.selectBafile = function(file) {
    // セッションチェック(TODO:関数化したい)
    $scope.executeApi('GET', '/api/accounts/session')
    .then(function() {
      const res = $scope.response.data;
      if(typeof res.session === "undefined") {
        $window.location.href = '/views/login.html';
      } else {
        // 初期表示時は処理しない
        if(!file){
          return;
        }

        // ファイル取得時の処理
        let reader = new FileReader();
        reader.onload = function(){
          $scope.$apply(function(){
            // ファイル名表示
            $scope.prevFileNameBA = file.name;

            // ファイルのバイナリデータと拡張子取得
            let dataStartIndex = reader.result.indexOf(',') + 1;
            let imgData = reader.result.slice(dataStartIndex);
            let extPeriodIndex = file.name.indexOf('.');
            let format = file.name.slice(extPeriodIndex + 1).toLowerCase();

            // 画像変換API
            let reqData = {
              format: format,
              data: imgData,
            };

            let loadingMsg = "";
            // 読込中メッセージを設定
            $translate(['loadingMsg.file-select'])
            .then(function (translations) {
              loadingMsg = translations['loadingMsg.file-select'];
            })
            .then(function() {
              // ローディング画面を表示
              $scope.startLoading(loadingMsg);
            });

            $scope.executeApi('POST', '/api/forms/thumbnail', reqData)
            .then(
              function() {
                // 成功時はファイルを表示
                let res = $scope.response.data;
                $scope.classBA = "CONTENT_HANDWRITTEN";
                $scope.prevImgSrcBA = 'data:image/'+ res.format + ';base64,' + res.data;
                $scope.formatBA = res.format;
                $scope.imgDataBA = res.data;
                $scope.errBA = false;
                $scope.showRotateBtn = true;
                // ローディング画面を閉じる処理
                $scope.endLoading();

              }, function() {
                // 失敗時はエラーメッセージを表示
                $scope.classBA = undefined;
                $scope.prevImgSrcBA = defimg;
                $scope.formatBA = "";
                $scope.imgDataBA = "";
                $scope.errDataBA = { errFileName : file.name };
                $scope.errBA = true;
                $scope.showRotateBtn = false;
                // ローディング画面を閉じる処理
                $scope.endLoading();
              }
            );
          });
          // タブを切り替える
          document.getElementById('tab-body-a').click();
        };
        // ファイルURL読み込み
        reader.readAsDataURL(file)
      }
    })
  };

  // 画像が選択されているかをチェックし、画像回転ボタンの表示を切り替える処理
  self.showRotateButton = function(targetId) {
    let target;
    if(targetId == "tab-front-b"){
      target = $scope.classFB;
    } else if(targetId == "tab-front-a") {
      target = $scope.classFA;
    } else if(targetId == "tab-body-b") {
      target = $scope.classBB;
    } else if(targetId == "tab-body-a") {
      target = $scope.classBA;
    }

    if(target){
      $scope.showRotateBtn = true;
    } else {
      $scope.showRotateBtn = false;
    }
  };

  // 画像回転ボタンを押下した時の処理
  self.rotateImg = function() {
    // 表示されているプレビューのidを取得
    let currentId = $('.form-area').find('img.active')[0].id;

    let targetImgSrc;
    if(currentId == "preview-front-b") {
      targetImgSrc = $scope.prevImgSrcFB;
    } else if(currentId == "preview-front-a") {
      targetImgSrc = $scope.prevImgSrcFA;
    } else if(currentId == "preview-body-b") {
      targetImgSrc = $scope.prevImgSrcBB;
    } else if(currentId == "preview-body-a") {
      targetImgSrc = $scope.prevImgSrcBA;
    }

    // MIMEタイプを取得する
    let typeStartIdx = targetImgSrc.indexOf(":") + 1;
    let typeEndIdx = targetImgSrc.indexOf(";");
    let img_type = targetImgSrc.substring(typeStartIdx, typeEndIdx);

    let img = new Image();
    img.onload = function() {
      // 画像の回転用にキャンバスを作成
      let canvas = document.createElement('canvas');
      // 画像の幅と高さを取得
      let width = img.width;
      let height = img.height;
      // キャンバスの幅と高さを設定
      canvas.width = height;
      canvas.height = width;

      let ctx = canvas.getContext('2d');
      // 画像回転処理
      ctx.rotate(90 * Math.PI / 180);
      ctx.translate(0, -height);

      // 画像を描画
      ctx.drawImage(img, 0, 0, width, height);
      // データURLを取得
      let rotatedSrc = canvas.toDataURL(img_type);

      // バイナリデータを取得
      let dataStartIdx = rotatedSrc.indexOf(',') + 1;
      let imgData = rotatedSrc.slice(dataStartIdx);

      $scope.$apply(function () {
        if(currentId == "preview-front-b") {
          $scope.prevImgSrcFB = rotatedSrc;
          $scope.imgDataFB = imgData;
        } else if(currentId == "preview-front-a") {
          $scope.prevImgSrcFA = rotatedSrc;
          $scope.imgDataFA = imgData;
        } else if(currentId == "preview-body-b") {
          $scope.prevImgSrcBB = rotatedSrc;
          $scope.imgDataBB = imgData;
        } else if(currentId == "preview-body-a") {
          $scope.prevImgSrcBA = rotatedSrc;
          $scope.imgDataBA = imgData;
        }
      });
    };
    img.src = targetImgSrc;
  };

  // フォーム解析ボタン処理
  $scope.recognizeForm = function() {
    // セッションチェック(TODO:関数化したい)
    $scope.executeApi('GET', '/api/accounts/session')
    .then(function() {
      const res = $scope.response.data;
      if(typeof res.session === "undefined") {
        $window.location.href = '/views/login.html';
      } else {

        // 入力チェック（ここでは表紙フォームのみチェック）
        $scope.errReqFB = false;
        $scope.errReqFA = false;
        if(!$scope.classFB && !$scope.errFB) {
          $scope.errReqFB = true;
        }
        if(!$scope.classFA && !$scope.errFA) {
          $scope.errReqFA = true;
        }
        if($scope.errFB || $scope.errReqFB || $scope.errFA || $scope.errReqFA) {
          return;
        }

        // フォーム登録用のオブジェクト作成
        let obj = new Object();
        obj.name = $scope.formName;
        obj.description = $scope.formDescription.replace(/\\n|\r\n|\r|\n/g, '\\n');
        obj.forms = new Array();
        let formIdx = 0;

        // 表紙（記入前）
        if($scope.classFB) {
          obj.forms[formIdx] = new Object();
          obj.forms[formIdx].class = $scope.classFB;
          obj.forms[formIdx].image = new Object();
          obj.forms[formIdx].image.format = $scope.formatFB;
          obj.forms[formIdx].image.data = $scope.imgDataFB;
          formIdx++;
        }
        // 表紙（記入後）
        if($scope.classFA) {
          obj.forms[formIdx] = new Object();
          obj.forms[formIdx].class = $scope.classFA;
          obj.forms[formIdx].image = new Object();
          obj.forms[formIdx].image.format = $scope.formatFA;
          obj.forms[formIdx].image.data = $scope.imgDataFA;
          formIdx++;
        }
        // 本文（記入前）
        if($scope.classBB) {
          obj.forms[formIdx] = new Object();
          obj.forms[formIdx].class = $scope.classBB;
          obj.forms[formIdx].image = new Object();
          obj.forms[formIdx].image.format = $scope.formatBB;
          obj.forms[formIdx].image.data = $scope.imgDataBB;
          formIdx++;
        }
        // 本文（記入後）
        if($scope.classBA) {
          obj.forms[formIdx] = new Object();
          obj.forms[formIdx].class = $scope.classBA;
          obj.forms[formIdx].image = new Object();
          obj.forms[formIdx].image.format = $scope.formatBA;
          obj.forms[formIdx].image.data = $scope.imgDataBA;
          formIdx++;
        }

        let loadingMsg = "";
        // 読込中メッセージを設定
        $translate(['loadingMsg.form-recognize'])
        .then(function (translations) {
          loadingMsg = translations['loadingMsg.form-recognize'];
        })
        .then(function() {
          // ローディング画面を表示
          $scope.startLoading(loadingMsg);
        });

        $scope.executeApi('POST', '/api/forms', angular.toJson(obj))
        .then(
          // アクセス成功
          function() {
            // 確認画面に引き継ぐ値を設定（画像選択に戻る場合も考慮）
            $scope.page.formClassFB = $scope.classFB;
            $scope.page.formClassFA = $scope.classFA;
            $scope.page.formClassBB = $scope.classBB;
            $scope.page.formClassBA = $scope.classBA;
            $scope.page.fileNameFB = $scope.prevFileNameFB;
            $scope.page.fileNameFA = $scope.prevFileNameFA;
            $scope.page.fileNameBB = $scope.prevFileNameBB;
            $scope.page.fileNameBA = $scope.prevFileNameBA;
            $scope.page.imgSrcFB = $scope.prevImgSrcFB;
            $scope.page.imgSrcFA = $scope.prevImgSrcFA;
            $scope.page.imgSrcBB = $scope.prevImgSrcBB;
            $scope.page.imgSrcBA = $scope.prevImgSrcBA;
            $scope.page.formatFB = $scope.formatFB;
            $scope.page.formatFA = $scope.formatFA;
            $scope.page.formatBA = $scope.formatBA;
            $scope.page.formatBB = $scope.formatBB;
            $scope.page.imgDataFB = $scope.imgDataFB;
            $scope.page.imgDataFA = $scope.imgDataFA;
            $scope.page.imgDataBA = $scope.imgDataBA;
            $scope.page.imgDataBB = $scope.imgDataBB;
            $scope.page.isPublicForm = $scope.isPublicForm;
            $scope.page.showRotateBtn = true;
            $scope.page.formNoBody = (!$scope.classBB || !$scope.classBA) ? true : false;

            // 画像のオリジナルサイズ取得（取得できるまで繰り返し:10回まで）※表紙のサイズを本文にも使用する
            let img = new Image();
            img.src = $scope.page.imgSrcFB;
            let orgImgWidth = 0;
            let errorCnt = 0;
            // 0.2秒処理を待つ
            let timer = setInterval(function() {
              if(img.width>0) {
                orgImgWidth = img.width;
                clearInterval(timer);
                self.main();
                $scope.$apply();
              } else {
                errorCnt++;
              }
              if(errorCnt==10) {
                console.log('【ERROR】矩形情報が取得できない為、遷移できません。');
                return;
              }
            }, 200);

            // データ整形のメイン処理
            self.main = function() {
              // レスポンスから$scopeに展開
              let obj = angular.fromJson($scope.response.data);

              // レスポンスデータの整形 *******************************  TODO 関数化したい↓
              // 解析結果
              let formDetailModel = obj.formDetailModel;
              // 項目マスタリスト
              let itemModelList = obj.itemModelList;

              // 配列itemModelListのname,aliasesを配列coverFormsに詰め替え（初期値設定）
              let tmpCoverForms = new Array();
              angular.forEach(itemModelList, function(value) {
                // aliasesをカンマ区切りで展開
                let aliases = '';
                if(value.aliases) {
                  for(let i=0; i<value.aliases.length; i++) {
                    aliases += value.aliases[i] + ', ';
                  }
                }
                // 各項目を設定
                let pushValue = {
                  "itemId": value.itemId,
                  "name": value.name,
                  "aliases": '（' + aliases.slice(0,-2) + '）',
                  "targetRect": [0,0,0,0],
                  "idKey": "",
                  "uncreate": true,
                  "scrap": (value.type=="SCRAP") ? true : false,
                  "displayOrder": value.appData.displayOrder,
                  "required": value.appData.required,
                  "group": value.appData.group
                };
                this.push(pushValue);
              }, tmpCoverForms);

              // 表示順にソート
              tmpCoverForms.sort(function(a,b){
                if( a.displayOrder < b.displayOrder ) {
                  return -1;
                }
                if( a.displayOrder > b.displayOrder ) {
                  return 1;
                }
                return 0;
              });

              // 項目データのみの状態でコピー（「本文」が設定された場合）
              let tmpContentForms = undefined;
              if(!$scope.page.formNoBody) {
                tmpContentForms = angular.copy(tmpCoverForms);
              }

              // TODO 以下、「表紙」と「本文」で処理を共通化したい
              // 「表紙」のrect情報を展開
              if(tmpCoverForms) {
                for(let i=0; i<tmpCoverForms.length; i++) {
                  let tmpFormDetail = undefined;
                  for(let j=0; j<formDetailModel.forms.length; j++) {
                    if(formDetailModel.forms[j].class=="COVER") {
                      // レスポンスの画像情報をFormServiceに設定
                      $scope.page.formatFB = formDetailModel.forms[j].image.format;
                      $scope.page.imgDataFB = formDetailModel.forms[j].image.data;
                      $scope.page.imgSrcFB = 'data:image/'+ $scope.page.formatFB + ';base64,' + $scope.page.imgDataFB;
                      tmpFormDetail = formDetailModel.forms[j];
                    }
                  }

                  if(tmpFormDetail) {
                    // キー情報設定
                    tmpCoverForms[i].idKey = 'F' + i;

                    // OCR読み取り対象矩形
                    if(tmpFormDetail.items) {
                      for(let j=0; j<tmpFormDetail.items.length; j++) {
                        if(tmpCoverForms[i].name===tmpFormDetail.items[j].name) {
                          tmpCoverForms[i].rect = tmpFormDetail.items[j].rect;
                          tmpCoverForms[i].targetRect = self.parseViewRect(tmpFormDetail.items[j].targetRect, orgImgWidth);
                          tmpCoverForms[i].uncreate = false;
                        }
                      }
                    }
                    // 画像切り取り用矩形
                    if(tmpFormDetail.scraps) {
                      for(let j=0; j<tmpFormDetail.scraps.length; j++) {
                        if(tmpCoverForms[i].name===tmpFormDetail.scraps[j].name) {
                          tmpCoverForms[i].targetRect = self.parseViewRect(tmpFormDetail.scraps[j].targetRect, orgImgWidth);
                          tmpCoverForms[i].uncreate = false;
                        }
                      }
                    }
                  }
                }
              }

              // 「本文」のrect情報を展開
              if(tmpContentForms) {
                for(let i=0; i<tmpContentForms.length; i++) {
                  let tmpFormDetail = undefined;
                  for(let j=0; j<formDetailModel.forms.length; j++) {
                    if(formDetailModel.forms[j].class=="CONTENT") {
                      // レスポンスの画像情報をFormServiceに設定
                      $scope.page.formatBB = formDetailModel.forms[j].image.format;
                      $scope.page.imgDataBB = formDetailModel.forms[j].image.data;
                      $scope.page.imgSrcBB = 'data:image/'+ $scope.page.formatBB + ';base64,' + $scope.page.imgDataBB;
                      tmpFormDetail = formDetailModel.forms[j];
                    }
                  }

                  if(tmpFormDetail) {
                    // キー情報設定
                    tmpContentForms[i].idKey = 'B' + i;

                    // OCR読み取り対象矩形
                    if(tmpFormDetail.items) {
                      for(let j=0; j<tmpFormDetail.items.length; j++) {
                        if(tmpContentForms[i].name===tmpFormDetail.items[j].name) {
                          tmpContentForms[i].rect = tmpFormDetail.items[j].rect;
                          tmpContentForms[i].targetRect = self.parseViewRect(tmpFormDetail.items[j].targetRect, orgImgWidth);
                          tmpContentForms[i].uncreate = false;
                        }
                      }
                    }
                    // 画像切り取り用矩形
                    if(tmpFormDetail.scraps) {
                      for(let j=0; j<tmpFormDetail.scraps.length; j++) {
                        if(tmpContentForms[i].name===tmpFormDetail.scraps[j].name) {
                          tmpContentForms[i].targetRect = self.parseViewRect(tmpFormDetail.scraps[j].targetRect, orgImgWidth);
                          tmpContentForms[i].uncreate = false;
                        }
                      }
                    }
                  }
                }
              }
              // *******************************  TODO 関数化したい↑

              // レスポンス情報をFormServiceに設定
              $scope.page.formId = obj.formDetailModel.formId;
              $scope.page.formName = obj.formDetailModel.name;
              $scope.page.formDescription = obj.formDetailModel.description;
              $scope.page.coverForms = tmpCoverForms;
              $scope.page.contentForms = tmpContentForms;
              $scope.page.fileSelectPage = false;
              
              // serverMsgAreaリセット
              $scope.serverMsgReset();
              // ローディング画面を閉じる処理
              $scope.endLoading();
            }

          }, function() {
            // serverMsgAreaリセット
            $scope.serverMsgReset();

            // フォーム名エラー
            if($scope.httpStatus==409) {
              $scope.errFormName = true;
              $scope.errDataFormName = { 'errFormName' : $scope.formName };

            // アクセス失敗
            } else {
              $scope.server.error = true;
              $translate(['serverMsg.access-err'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.access-err'];
              });
            }

            // ローディング画面を閉じる処理
            $scope.endLoading();
          });
        }
      })
    };

    // rectの値を表示するサイズに変換
    self.parseViewRect = function(targetRect, orgImgWidth) {
      // 表示画像幅を550px固定で計算
      let returnRect = new Array();
      angular.forEach(targetRect, function(rect) {
        returnRect.push(rect * (550 / orgImgWidth))
      });
      return returnRect
    }

  }
]);

//directive
app.directive('fileModel', [ '$parse', '$timeout',
  function($parse, $timeout){
    return{
      restrict: 'A',
      link: function(scope,element,attrs){
        let model = $parse(attrs.fileModel);
        element.bind('change',function(){
          scope.$apply(function(){
            model.assign(scope,element[0].files[0]);
          });
        });
      }
    };
}]);

// フォーム新規登録内容確認/フォーム編集
app.controller('FormRectCtrl', [ '$scope', '$http', '$filter', '$translate', '$state', '$sce', '$window', '$transitions', '$q', 'FormService', 'ServerMsgService',
  function($scope, $http, $filter, $translate, $state, $sce, $window, $transitions, $q, FormService, ServerMsgService) {
    const self = this;

    // ページ情報共有
    $scope.server = ServerMsgService;
    $scope.page = FormService;

    let onBeforeunloadHandler = function(event) {
      // フォーム編集画面の場合はSessionStorageに値を保存
      // TODO フォーム登録画面で再読み込みした際の画面遷移の不具合修正後、フォーム登録画面もSessionStorageに値を保存する
      if($state.current.name == 'form-edit') {
        sessionStorage.setItem( 'page', JSON.stringify($scope.page) );
      }
    };

    // ページの再読み込みを監視する
    $window.addEventListener('beforeunload', onBeforeunloadHandler, false);

    // SessionStorageから値を取得
    let tempPage = JSON.parse( sessionStorage.getItem('page') );
    if(tempPage) {
      $scope.page = tempPage;
      // SessionStorageを削除
      sessionStorage.clear();
    }

    // 初期値設定
    $scope.formName = $scope.page.formName;
    $scope.formDescription = $scope.page.formDescription;
    if($scope.formDescription != undefined) {
      $scope.formDescription = $scope.formDescription.replace(/\\n/g, '\n');
    }
    $scope.isPublicForm = $scope.page.isPublicForm;
    $scope.tags = $scope.page.tags;
    $scope.noBody = $scope.page.formNoBody;
    $scope.coverForms = $scope.page.coverForms;
    $scope.contentForms = $scope.page.contentForms;
    $scope.rectFront = true;

    // popover内のメッセージをHTML形式で表示する
    $translate(['form.rect-required-msg'])
    .then(function (translations) {
      $scope.rectRequiredMsg = $sce.trustAsHtml(translations['form.rect-required-msg']);
    });

    // 画像表示領域の固定値（初期値）
    let imgWidthF = 550;
    let imgHeightF = 1100;
    let imgWidthB = 550;
    let imgHeightB = 1100;

    // マウスダウン確認用
    let mouseDown = false;

    // マウス始点
    let basePoint = 0;
    let rectX = 0;
    let rectY = 0;
    let x;
    let y;

    let canvas;
    let context;
    let index = 0;

    // 線の書式設定
    let contextType = "2d";
    let lineWidth = 2;
    let lineCap = "round";
    let targetColor = "red";
    let disableColor = "blue";

    // 矩形サイズの範囲指定
    let maxRectWidth = 550;
    let minRectWidth = 5;
    let maxRectHeight = 800;
    let minRectHeight = 5;

    // 操作キャンバスを変更する（すべて再描画）
    self.changeCanvas = function(idKey) {
      let canvasId = "canvasArea" + idKey;
      let forms = $scope.coverForms;
      if(idKey.substr(0,1) == "B") {
        forms = $scope.contentForms;
      }

      // キャンバスの色変更
      let color;
      for(let i=0; i<forms.length; i++) {
        // 操作対象のみを「赤」に変更（非対象は「青」）
        color = disableColor;
        let btnClass = "rect-item";
        // ボタンの表示を選択状態とする
        if(canvasId == "canvasArea" + forms[i].idKey) {
          color = targetColor;
          btnClass += " target-rect";
          index = i;
        // ボタンの表示を未作成状態とする
        } else if(forms[i].uncreate) {
          btnClass += " required-rect";
        }
        // 矩形選択ボタンの表示変更
        let btn = document.getElementById("targetRect" + forms[i].idKey);
        btn.className = btnClass;

        // 各キャンバスの表示変更（再描画）
        canvas = document.getElementById("canvasArea" + forms[i].idKey);
        context = canvas.getContext(contextType);
        context.strokeStyle = color;
        context.fillStyle = color;
        context.lineWidth = lineWidth;
        context.lineCap = lineCap;
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.strokeRect(forms[i].targetRect[0],forms[i].targetRect[1],forms[i].targetRect[2],forms[i].targetRect[3]);
      }

      // 現在のidKeyを設定
      $scope.nowIdKey = idKey;

      // キャンバスの変更
      canvas = document.getElementById("canvasArea" + idKey);
      
      // 固定枠指定用のチェックボックスのレイアウト変更
      let checkBoxList = document.getElementById("fixedPosList").children;
      for(let i=0; i<checkBoxList.length; i++) {
        checkBoxList[i].classList.remove("select-rect");
        checkBoxList[i].classList.add("rect-fixed-pos");
      }
      document.getElementById("fixedPos" + idKey).parentNode.classList.remove("rect-fixed-pos");
      document.getElementById("fixedPos" + idKey).parentNode.classList.add("select-rect");
    };
    
    // 描画の始点設定
    self.setStartPoint = function(e){
      // マウスダウン
      mouseDown = true;

      // canvasの絶対座標を取得
      basePoint = e.target.getBoundingClientRect() ;

      // マウスの座標（始点）をセット
      rectX = e.clientX - basePoint.left;
      rectY = e.clientY - basePoint.top;
    };

    // 描画処理
    self.drawing = function(e){
      // マウスボタンが押されていれば描画
      if (mouseDown){
        // コンテキストの取得
        context = canvas.getContext(contextType);

        // マウスの現在の座標を取得
        x = e.clientX - basePoint.left;
        y = e.clientY - basePoint.top;

        // 線の色セット
        context.strokeStyle = targetColor;
        context.fillStyle = targetColor;

        // 線の太さセット
        context.lineWidth = lineWidth;

        // 線端の形状セット
        context.lineCap = lineCap;

        // 線の形状セット
        let rectWidth = x - rectX;
        let rectHeight = y - rectY;

        // 画面クリア&描画
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.strokeRect(rectX,rectY,rectWidth,rectHeight);
      }
    };

    // 描画終了（幅・高さの取得）
    self.drawEnd = function(idKey){
      if (mouseDown){
        // マウスアップ
        mouseDown = false;

        // 高さ・幅の取得
        let rectWidth;
        let rectHeight;
        if(x > rectX){
          rectWidth = x - rectX;
        } else {
          rectWidth = rectX - x;
        }
        if(y > rectY){
          rectHeight = y - rectY;
        } else {
          rectHeight = rectY - y;
        }

        // 本文
        if(idKey.substr(0,1) == "B") {
          // データ保持(幅、高さのどちらかが範囲外の場合無視する)
          if(rectWidth>=minRectWidth && rectWidth<=maxRectWidth
              && rectHeight>=minRectHeight && rectHeight<=maxRectHeight
              && x<=imgWidthF && y<=imgHeightF) {
            $scope.contentForms[index].targetRect[0] = rectX;
            $scope.contentForms[index].targetRect[1] = rectY;
            $scope.contentForms[index].targetRect[2] = rectWidth;
            $scope.contentForms[index].targetRect[3] = rectHeight;
            $scope.contentForms[index].uncreate = false;
            // scrap以外の項目はダミーrectを設定する
            if(!$scope.contentForms[index].scrap) {
                let tmpRect = self.parseRect($scope.contentForms[index].targetRect, false);
                $scope.contentForms[index].rect = new Array(4);
                $scope.contentForms[index].rect[0] = $filter('number')(parseInt(tmpRect[0]) + (0.25 * tmpRect[2]), 0).replace(',', '');
                $scope.contentForms[index].rect[1] = $filter('number')(parseInt(tmpRect[1]) + (0.5 * tmpRect[3]), 0).replace(',', '');
                $scope.contentForms[index].rect[2] = 1;
                $scope.contentForms[index].rect[3] = 1;
            }

          }
          // 画面クリア&描画
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.strokeRect($scope.contentForms[index].targetRect[0],$scope.contentForms[index].targetRect[1],$scope.contentForms[index].targetRect[2],$scope.contentForms[index].targetRect[3]);

        //表紙
        } else {
          // データ保持(幅、高さのどちらかが範囲外の場合無視する)
          if(rectWidth>=minRectWidth && rectWidth<=maxRectWidth
              && rectHeight>=minRectHeight && rectHeight<=maxRectHeight
              && x<=imgWidthB && y<=imgHeightB) {
            $scope.coverForms[index].targetRect[0] = rectX;
            $scope.coverForms[index].targetRect[1] = rectY;
            $scope.coverForms[index].targetRect[2] = rectWidth;
            $scope.coverForms[index].targetRect[3] = rectHeight;
            $scope.coverForms[index].uncreate = false;
            // scrap以外の項目はダミーrectを設定する
            if(!$scope.coverForms[index].scrap) {
                let tmpRect = self.parseRect($scope.coverForms[index].targetRect, true);
                $scope.coverForms[index].rect = new Array(4);
                $scope.coverForms[index].rect[0] = $filter('number')(parseInt(tmpRect[0]) + (0.25 * tmpRect[2]), 0).replace(',', '');
                $scope.coverForms[index].rect[1] = $filter('number')(parseInt(tmpRect[1]) + (0.5 * tmpRect[3]), 0).replace(',', '');
                $scope.coverForms[index].rect[2] = 1;
                $scope.coverForms[index].rect[3] = 1;
            }

          }
          // 画面クリア&描画
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.strokeRect($scope.coverForms[index].targetRect[0],$scope.coverForms[index].targetRect[1],$scope.coverForms[index].targetRect[2],$scope.coverForms[index].targetRect[3]);
        }

      }
    };

    // 矩形選択取り消し（TODO 本文には未対応）
    self.cancelRect = function(){
      $scope.coverForms[index].targetRect = [0,0,0,0];
      $scope.coverForms[index].uncreate = true;
    };

    // 対象フォーム切替（表紙⇔本文）
    self.changeFrom = function(pageKey){
      $scope.rectFront = !$scope.rectFront;
      if(pageKey == "B") {
        self.changeCanvas($scope.contentForms[index].idKey);
      } else {
        self.changeCanvas($scope.coverForms[index].idKey);
      }
    }

    // 画像選択画面に戻る
    self.backPage = function() {
      // serverMsgAreaリセット
      $scope.serverMsgReset();

      // 矩形選択画面の値をファクトリーにセット
      $scope.page.formName = $scope.formName;
      $scope.page.formDescription = $scope.formDescription;
      $scope.page.isPublicForm = $scope.isPublicForm;
      $scope.page.fileSelectPage = true;

      closeModal();
    };

    //* フォーム登録・更新処理 *//
    self.formRegist = function(isCreate) {

      // セッションチェック(TODO:関数化したい)
      $scope.executeApi('GET', '/api/accounts/session')
      .then(function() {
         const res = $scope.response.data;
         if(typeof res.session === "undefined") {
           $window.location.href = '/views/login.html';
         } else {
            // 入力チェック
            if(self.checkInput()) {
              return;
            }

            // フォーム登録用のオブジェクト作成
            let returnObj = new Object();
            returnObj.name = $scope.formName;
            returnObj.description = $scope.formDescription.replace(/\\n|\r\n|\r|\n/g, "\\n");
            // tagsの設定
            let accessTarget = "PRIVATE";
            if($scope.isPublicForm && $scope.isAdmin) {
              accessTarget = "PUBLIC";
            }
            // 登録か更新で処理を分ける
            if($scope.tags == undefined) {
              $scope.tags = [$scope.username, accessTarget, $scope.loginId];
            } else {
              let updaterIds = $scope.tags.slice(2, $scope.tags.length);
              if(updaterIds.indexOf($scope.loginId) == -1) {
                $scope.tags.push($scope.loginId);
              }
              $scope.tags[0] = $scope.username;
              $scope.tags[1] = accessTarget;
            }
            returnObj.tags = $scope.tags;
            returnObj.forms = new Array();
            returnObj.appData = new Object();
            returnObj.appData.registerItemIds = new Array();

            // 表紙
            returnObj.forms[0] = new Object();
            returnObj.forms[0].class = 'COVER';
            returnObj.forms[0].items = new Array();
            returnObj.forms[0].scraps = new Array();
            let itemIdx = 0;
            let scrapIdx = 0;
            let rectIdx = 0;
            for(let i=0; i<$scope.coverForms.length; i++) {
              // 必須ではなく未作成の場合、設定しない
              if($scope.coverForms[i].uncreate) {
                continue;
              }
              let rectInfo;
              if($scope.coverForms[i].scrap) {
                returnObj.forms[0].scraps[scrapIdx] = new Object();
                rectInfo = returnObj.forms[0].scraps[scrapIdx];
                scrapIdx++;
              } else {
                returnObj.forms[0].items[itemIdx] = new Object();
                rectInfo = returnObj.forms[0].items[itemIdx];
                // rectが設定されていない(固定枠から固定枠ではない設定に戻した場合)にrectを設定
                if(rectInfo.rect == null && !$scope.coverForms[i].fixedPos) {
                  let tmpRect = self.parseRect($scope.coverForms[index].targetRect, false);
                  rectInfo.rect = new Array(4);
                  rectInfo.rect[0] = $filter('number')(parseInt(tmpRect[0]) + (0.25 * tmpRect[2]), 0).replace(',', '');
                  rectInfo.rect[1] = $filter('number')(parseInt(tmpRect[1]) + (0.5 * tmpRect[3]), 0).replace(',', '');
                  rectInfo.rect[2] = 1;
                  rectInfo.rect[3] = 1;
                } else {
                  rectInfo.rect = $scope.coverForms[i].rect;
                }
                rectInfo.fixedPos = $scope.coverForms[i].fixedPos;
                itemIdx++;
              }
              returnObj.appData.registerItemIds[rectIdx] = $scope.coverForms[i].itemId;
              rectIdx++;
              rectInfo.name = $scope.coverForms[i].name;
              rectInfo.targetRect = self.parseRect($scope.coverForms[i].targetRect, true);
            }
            returnObj.forms[0].image = new Object();
            returnObj.forms[0].image.format = $scope.page.formatFB;
            returnObj.forms[0].image.data = $scope.page.imgDataFB;

            // 本文
            if($scope.contentForms) {
              returnObj.forms[1] = new Object();
              returnObj.forms[1].class = 'CONTENT';
              returnObj.forms[1].items = new Array();
              returnObj.forms[1].scraps = new Array();
              itemIdx = 0;
              scrapIdx = 0;
              rectIdx = 0;
              for(let i=0; i<$scope.contentForms.length; i++) {
                // 必須ではなく未作成の場合、設定しない
                if($scope.contentForms[i].uncreate) {
                  continue;
                }
                let rectInfo;
                if($scope.contentForms[i].scrap) {
                  returnObj.forms[1].scraps[scrapIdx] = new Object();
                  rectInfo = returnObj.forms[1].scraps[scrapIdx];
                  scrapIdx++;
                } else {
                  returnObj.forms[1].items[itemIdx] = new Object();
                  rectInfo = returnObj.forms[1].items[itemIdx];
                  // rectが設定されていない(固定枠から固定枠ではない設定に戻した場合)にrectを設定
                  if(rectInfo.rect == null && !$scope.contentForms[i].fixedPos) {
                    let tmpRect = self.parseRect($scope.contentForms[index].targetRect, false);
                    rectInfo.rect.rect = new Array(4);
                    rectInfo.rect[0] = $filter('number')(parseInt(tmpRect[0]) + (0.25 * tmpRect[2]), 0).replace(',', '');
                    rectInfo.rect[1] = $filter('number')(parseInt(tmpRect[1]) + (0.5 * tmpRect[3]), 0).replace(',', '');
                    rectInfo.rect[2] = 1;
                    rectInfo.rect[3] = 1;
                  } else {
                    rectInfo.rect = $scope.contentForms[i].rect;
                  }
                  rectInfo.fixedPos = $scope.contentForms[i].fixedPos;
                  itemIdx++;
                }
                returnObj.appData.registerItemIds[rectIdx] = $scope.contentForms[i].itemId;
                rectIdx++;
                rectInfo.name = $scope.contentForms[i].name;
                rectInfo.targetRect = self.parseRect($scope.contentForms[i].targetRect, false);
              }
              returnObj.forms[1].image = new Object();
              returnObj.forms[1].image.format = $scope.page.formatBB;
              returnObj.forms[1].image.data = $scope.page.imgDataBB;
            }

            // フォーム新規登録画面の場合はPOST、フォーム編集画面の場合はPUTを変数に代入
            let methodName = isCreate ? "POST" : "PUT";

            // 読込中メッセージを設定
            let setLoadingMsg = function(isCreate) {
              let d = $q.defer();
              if(isCreate) {
                $translate(['loadingMsg.form-create'])
                .then(function (translations) {
                  loadingMsg = translations['loadingMsg.form-create'];
                  d.resolve();
                })
              } else {
                $translate(['loadingMsg.form-edit'])
                .then(function (translations) {
                  loadingMsg = translations['loadingMsg.form-edit'];
                  d.resolve();
                })
              }
              return d.promise;
            };

            let loadingMsg = "";
            setLoadingMsg(isCreate)
            .then(function() {
              // ローディング画面を表示
              $scope.startLoading(loadingMsg);
            });

            // フォーム登録(更新)API
            $scope.executeApi(methodName, '/api/forms/'+$scope.page.formId, angular.toJson(returnObj))
            .then(
              function() {
                // 成功時はメッセージを表示
                let res = $scope.response.data;

                // serverMsgAreaリセット
                $scope.serverMsgReset();

                $scope.server.info = true;
                const targetformData = {
                  formName: $scope.formName,
                  type: ""
                };
                $translate(['regist', 'update'])
                .then(function (translations) {
                  if(isCreate) {
                    targetformData.type = translations['regist'];
                  } else {
                    targetformData.type = translations['update'];
                  }
                })
                .then(function() {
                  $translate(['serverMsg.form-fix'], targetformData)
                  .then(function (translations) {
                    $scope.server.msg = translations['serverMsg.form-fix'];
                  });
                })
                .then(function() {
                  // ローディング画面を閉じる処理
                  $scope.endLoading();
                  // 登録フォーム一覧画面に遷移
                  $scope.page.fileSelectPage = true;
                  $state.go('forms');
                });

              }, function() {
                // serverMsgAreaリセット
                $scope.serverMsgReset();

                // フォーム名エラー
                if($scope.httpStatus==409) {
                  $scope.errFormName = true;
                  $scope.errDataFormName = { 'errFormName' : $scope.formName };

                } else {
                  $scope.server.error = true;
                  // ログインユーザー以外のIDへのアクセス
                  if($scope.httpStatus==403) {
                    $translate(['serverMsg.invalid-access'])
                    .then(function (translations) {
                      $scope.server.msg = translations['serverMsg.invalid-access'];
                    });
                  // 表紙・本文の相似エラー
                  } else if($scope.httpStatus==412) {
                    $translate(['serverMsg.form-classifiable'])
                    .then(function (translations) {
                      $scope.server.msg = translations['serverMsg.form-classifiable'];
                    });
                    $scope.page.fileSelectPage = true;

                    // サーバエラー
                    } else {
                      $translate(['serverMsg.access-err'])
                      .then(function (translations) {
                        $scope.server.msg = translations['serverMsg.access-err'];
                      });
                    }
                  }
                  // ローディング画面を閉じる処理
                  $scope.endLoading();
                }
              )
            }
          }
        )
      }

    // 入力チェック（必須項目）
    self.checkInput = function() {
      let result = false;
      $scope.errReqFormName = false;
      $scope.errMaxLength = false;
      $scope.errReqFormDescription = false;
      $scope.errReqRect = false;

      // フォーム名
      if($scope.formName==undefined || $scope.formName=='') {
        result = true;
        $scope.errReqFormName = true;
      } else if ($scope.formName.length>64) {
        result = true;
        $scope.errMaxLength = true;
      }
      // フォーム説明
      if($scope.formDescription==undefined || $scope.formDescription=='') {
        result = true;
        $scope.errReqFormDescription = true;
      }
      // 必須の矩形情報が選択されているか
      for(let i=0; i<$scope.coverForms.length; i++) {
        if($scope.coverForms[i].required && $scope.coverForms[i].uncreate) {
          result = true;
          $scope.errReqRect = true;
          break;
        }
      }
      if(!$scope.errReqRect && $scope.contentForms) {
        for(let i=0; i<$scope.contentForms.length; i++) {
         if($scope.contentForms[i].required && $scope.contentForms[i].uncreate) {
            result = true;
            $scope.errReqRect = true;
            break;
          }
        }
      }

      return result;
    }


    // rectの値を実際のサイズに戻す（小数点以下四捨五入）
    self.parseRect = function(targetRect, frontFlag) {
      // 画像のオリジナルサイズ取得
      let img = new Image();
      img.src = frontFlag ? $scope.page.imgSrcFB : $scope.page.imgSrcBB;

      // 表示画像幅を550px固定で計算
      let returnRect = new Array();
      angular.forEach(targetRect, function(rect) {
        let number = rect * (img.width / 550);
        returnRect.push($filter('number')(number, 0).replace(',', ''))
      });
      return returnRect
    }

    // 初期表示
    angular.element(function() {
      self.changeCanvas($scope.coverForms[index].idKey);
    });

    // フォーム編集画面から離れたときを監視
    // TODO 画面遷移の監視が複数回行われるため修正
    $transitions.onStart({from: 'form-edit'}, function(trans) {
      // factoryを初期化する
      $scope.page.fileSelectPage = true;
      $scope.page.formName = "";
      $scope.page.formDescription = "";
      $scope.page.isPublicForm = true;
      $scope.page.tags = undefined;
      $scope.page.formNoBody = true;
      $scope.page.formClassFB = undefined;
      $scope.page.formClassFA = undefined;
      $scope.page.formClassBB = undefined;
      $scope.page.formClassBA = undefined;
      $scope.page.fileNameFB = "";
      $scope.page.fileNameFA = "";
      $scope.page.fileNameBB = "";
      $scope.page.fileNameBA = "";
      $scope.page.imgSrcFB = "img/preview.png";
      $scope.page.imgSrcFA = "img/preview.png";
      $scope.page.imgSrcBB = "img/preview.png";
      $scope.page.imgSrcBA = "img/preview.png";
      $scope.page.formatFB = "";
      $scope.page.formatFA = "";
      $scope.page.formatBB = "";
      $scope.page.formatBA = "";
      $scope.page.imgDataFB = "";
      $scope.page.imgDataFA = "";
      $scope.page.imgDataBB = "";
      $scope.page.imgDataBA = "";
      $scope.page.coverForms = undefined;
      $scope.page.contentForms = undefined;

      // 画面再読み込み監視イベントを削除
      // TODO フォーム登録画面で再読み込みした際の画面遷移の不具合修正後、フォーム登録画面も同様の処理を実装する
      $window.removeEventListener('beforeunload', onBeforeunloadHandler, false);
    });

}]);

// 登録フォーム一覧
app.controller('FormListCtrl', [ '$scope', '$translate', '$state', '$filter', '$sce', '$window', '$q', 'FormListService', 'FormService', 'ServerMsgService',
  function($scope, $translate, $state, $filter, $sce, $window, $q, FormListService, FormService, ServerMsgService) {

  const self = this;

  // factoryからデータ共有
  $scope.form = FormListService;
  $scope.page = FormService;
  $scope.server = ServerMsgService;

  let tmpFormInfos = new Array();

  let loadingMsg = "";
  // 読込中メッセージを設定
  $translate(['loadingMsg.forms'])
  .then(function (translations) {
    loadingMsg = translations['loadingMsg.forms'];
  })
  .then(function() {
    // ローディング画面を表示
    $scope.startLoading(loadingMsg);
  });
  
  // ログインIDが取得できるまで処理を待つ
  let errorCnt = 0;
  let timer = setInterval(function() {
    if($scope.loginId != undefined) {
      clearInterval(timer);
      // フォーム一覧取得APIを実行
      $scope.executeApi('GET', '/api/forms/my/' + $scope.loginId)
        .then(
          // アクセス成功
          function() {
            const res = $scope.response.data;
    
            let resStatus = true;
            // セッションが切れているか確認
            if(res.length > 0 && typeof res[0].formId === "undefined") {
              resStatus = false;
            }
            if(resStatus) {
    
              // 日付を降順でソート
              res.sort(function(a,b){
                if(a.updated > b.updated) {
                  return -1;
                }
                if(a.updated < b.updated) {
                  return 1;
                }
                return 0;
              });
    
              for(let i=0; i<res.length; i++) {
                let formInfo = new Object();
                // レスポンスの値を変数に格納する
                formInfo.formId = res[i].formId;
                formInfo.name = res[i].name;
                formInfo.description = res[i].description;
                formInfo.updater = res[i].tags[0];
                formInfo.isEdit = false;
                // 全学用の用紙かを判定
                if(!(res[i].tags.indexOf("PUBLIC") >= 0) || $scope.isAdmin) {
                  formInfo.isEdit = true;
                }
                // 日付のフォーマットを指定
                let date = new Date( res[i].updated * 1000 );
                formInfo.updated = formatDate(date, 'yyyy/MM/dd hh:mm:ss');
                tmpFormInfos[i] = formInfo;
              };
            }
    
            $scope.formInfos = tmpFormInfos;
    
            // ローディング画面を閉じる処理
            $scope.endLoading();
    
            // セッションが切れていた場合、トップ画面へ遷移する
            if(!resStatus) {
              resStatus = true;
              $window.location.href = '/views/login.html';
            }
          // アクセス失敗
          }, function() {
            // serverMsgAreaリセット
            $scope.serverMsgReset();
            $scope.server.error = true;
            
            // ログインユーザー以外のIDへのアクセス
            if($scope.httpStatus==403) {
              $translate(['serverMsg.invalid-access'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.invalid-access'];
              });
            // サーバーエラー
            } else {
              $translate(['serverMsg.access-err'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.access-err'];
              })
            }
            // ローディング画面を閉じる処理
            $scope.endLoading();
          }
        );
    } else {
      errorCnt++;
    }
    if(errorCnt==10) {
      console.log('【ERROR】ユーザー情報が取得できませんでした。');
      return;
    }
  }, 100);

    // 用紙の選択処理
    self.selectForm = function(idx, event) {
      let className = event.target.className;
      if(!className.match(/glyphicon-search|form-name/)) {
        self.setFormInfo(idx);
        if(Object.keys($scope.form.formInfo).length!=0) {
            angular.element('#form-select tbody tr').removeClass('info');
            angular.element('#' + $scope.form.formInfo.formId).addClass('info');
            // OcrJobCreateCtrlの値を書き換える
            $scope.$parent.targetFormSelect = true;
            $scope.$parent.selectedForm = $scope.form.formInfo.name;
        }
      }
    };

    // 選択されたフォームの情報をfactoryに設定する
    self.setFormInfo = function(idx) {
      $scope.form.formInfo = $scope.formInfos[idx];
    };

    // プレビュー表示処理
    self.showPreview = function(idx) {
      self.setFormDetail(idx)
      .then(function(){
        if(!$scope.server.error) {
          angular.element('#modal-preview').modal('show');
         }
      });
    }

    // プレビュー対象のフォーム情報とimageをファクトリーにセットする
    self.setFormDetail = function(listIdx) {
      let d = $q.defer();
      // セッションチェック(TODO:関数化したい)
      $scope.executeApi('GET', '/api/accounts/session')
      .then(function() {
        const res = $scope.response.data;
        if(typeof res.session === "undefined") {
          $window.location.href = '/views/login.html';
          d.resolve();
        } else {

          self.setFormInfo(listIdx);
          // フォーム詳細のみ改行表示
          $scope.form.formInfo.descriptionDetail = $sce.trustAsHtml($scope.form.formInfo.description.replace(/\n/g, '<br />'));

          let loadingMsg = "";
          // 読込中メッセージを設定
            $translate(['loadingMsg.form-preview'])
            .then(function (translations) {
              loadingMsg = translations['loadingMsg.form-preview'];
            })
            .then(function() {
              // ローディング画面を表示
              $scope.startLoading(loadingMsg);
            });

            $scope.executeApi('GET', '/api/forms/' + $scope.form.formInfo.formId)
            .then(
              // アクセス成功
              function() {
                let res = $scope.response.data;
                $scope.form.formImgF = 'data:image/' + res.formDetailModel.forms[0].image.format +
                                       ';base64,' + res.formDetailModel.forms[0].image.data;
                $scope.form.formNoBody = true;
                // 本文が登録されているか判定する
                if(res.formDetailModel.forms.length != 1) {
                  $scope.form.formImgB = 'data:image/' + res.formDetailModel.forms[1].image.format +
                                         ';base64,' + res.formDetailModel.forms[1].image.data;
                  $scope.form.formNoBody = false;
                }

                // serverMsgAreaリセット
                $scope.serverMsgReset();
                // ローディング画面を閉じる処理
                $scope.endLoading();
                d.resolve();

              // アクセス失敗
              }, function() {
                // serverMsgAreaリセット
                $scope.serverMsgReset();

                $scope.server.error = true;
                // 権限のないフォームへのアクセス
                if($scope.httpStatus==403) {
                  $translate(['serverMsg.invalid-access'])
                  .then(function (translations) {
                    $scope.server.msg = translations['serverMsg.invalid-access'];
                  });
                // 指定されたIDのフォームが存在しない
                } else if($scope.httpStatus==404) {
                  $translate(['serverMsg.form-not-exist'])
                  .then(function (translations) {
                    $scope.server.msg = translations['serverMsg.form-not-exist'];
                  });
                  // サーバーエラー
                } else {
                  $translate(['serverMsg.access-err'])
                  .then(function (translations) {
                    $scope.server.msg = translations['serverMsg.access-err'];
                  });
                }
                // ローディング画面を閉じる処理
                $scope.endLoading();
                d.resolve();
              }
            );
          }
        }
      )
      return d.promise;
    };

    // プレビューが閉じられたときを監視し、factoryを削除する
    angular.element('#modal-preview').on('hidden.bs.modal', function (e) {
      if($state.current.name == 'forms') {
        $scope.form.formInfo = new Object();
        $scope.form.formImgF = "";
        $scope.form.firnImgB = "";
      }
    });

    // フォーム編集画面に遷移する処理
    self.transEdit = function(idx) {

      // セッションチェック(TODO:関数化したい)
      $scope.executeApi('GET', '/api/accounts/session')
      .then(function() {
        const res = $scope.response.data;
        if(typeof res.session === "undefined") {
          $window.location.href = '/views/login.html';
        } else {

          let loadingMsg = "";
          // 読込中メッセージを設定
          $translate(['loadingMsg.form-preview'])
          .then(function (translations) {
            loadingMsg = translations['loadingMsg.form-preview'];
          })
          .then(function() {
            // ローディング画面を表示
            $scope.startLoading(loadingMsg);
          });

          // フォーム詳細を取得するAPIを実行
          $scope.executeApi('GET', '/api/forms/' + $scope.formInfos[idx].formId)
          .then(
            // アクセス成功
            function() {
              $scope.page.imgSrcFB = 'data:image/' + $scope.response.data.formDetailModel.forms[0].image.format
                                   + ';base64,' + $scope.response.data.formDetailModel.forms[0].image.data;
              $scope.page.formNoBody = true;
              if($scope.response.data.formDetailModel.forms.length != 1) {
                $scope.page.imgSrcBB = 'data:image/' + $scope.response.data.formDetailModel.forms[1].image.format
                                     + ';base64,' + $scope.response.data.formDetailModel.forms[1].image.data;
                $scope.page.formNoBody = false;
              }

              // レスポンスから$scopeに展開
              let obj = angular.fromJson($scope.response.data);

              // レスポンスデータの整形 *******************************  TODO 関数化したい↓
              // 画像のオリジナルサイズ取得（取得できるまで繰り返し:10回まで）※表紙のサイズを本文にも使用する
              let img = new Image();
              img.src = $scope.page.imgSrcFB;
              let orgImgWidth = 0;
              let errorCnt = 0;
              // 0.2秒処理を待つ
              let timer = setInterval(function() {
                if(img.width>0) {
                  orgImgWidth = img.width;
                  clearInterval(timer);
                  self.main();
                } else {
                  errorCnt++;
                }
                if(errorCnt==10) {
                  console.log('【ERROR】矩形情報が取得できない為、遷移できません。');
                  return;
                }
              }, 200);

              // データ整形のメイン処理
              self.main = function() {
                // 解析結果
                let formDetailModel = obj.formDetailModel;
                // 項目マスタリスト
                let itemModelList = obj.itemModelList;

                // 配列itemModelListのname,aliasesを配列coverFormsに詰め替え（初期値設定）
                let tmpCoverForms = new Array();
                angular.forEach(itemModelList, function(itemModel) {
                  // aliasesをカンマ区切りで展開
                  let aliases = '';
                  if(itemModel.aliases) {
                    angular.forEach(itemModel.aliases, function(alias) {
                      aliases += alias + ', ';
                    });
                  }
                  // 各項目を設定
                  let pushValue = {
                    "itemId": itemModel.itemId,
                    "name": itemModel.name,
                    "aliases": '（' + aliases.slice(0,-2) + '）',
                    "targetRect": [0,0,0,0],
                    "idKey": "",
                    "uncreate": true,
                    "scrap": (itemModel.type=="SCRAP"),
                    "displayOrder": itemModel.appData.displayOrder,
                    "required": itemModel.appData.required,
                    "group": itemModel.appData.group
                  };
                  this.push(pushValue);
                }, tmpCoverForms);

                // 表示順にソート
                tmpCoverForms.sort(function(a,b){
                  if( a.displayOrder < b.displayOrder ) {
                    return -1;
                  }
                  if( a.displayOrder > b.displayOrder ) {
                    return 1;
                  }
                  return 0;
                });

                // 項目データのみの状態でコピー（「本文」が設定された場合）
                let tmpContentForms = undefined;
                if(!$scope.page.formNoBody) {
                  tmpContentForms = angular.copy(tmpCoverForms);
                }

                // フォームに登録された項目のみ使用する
                angular.forEach(formDetailModel.forms, function(form, i) {
                if(form.class=="COVER") {
                  let tmpList = angular.copy(tmpCoverForms);
                  let delIdx = 0;
                  angular.forEach(tmpCoverForms, function(tmpCoverForm, j) {
                    if($filter('filter')(formDetailModel.appData.registerItemIds, tmpCoverForm.itemId).length==0) {
                      tmpList.splice(j-delIdx, 1);
                      delIdx++;
                    }
                  });
                  tmpCoverForms = tmpList;
                } else if(!$scope.page.formNoBody && form.class=="CONTENT") {
                  let tmpList = angular.copy(tmpContentForms);
                  let delIdx = 0;
                  angular.forEach(tmpContentForms, function(tmpContentForm, j) {
                    if($filter('filter')(formDetailModel.appData.registerItemIds, tmpContentForm.itemId).length==0) {
                      tmpList.splice(j-delIdx, 1);
                      delIdx++;
                    }
                  });
                  tmpContentForms = tmpList;
                }
              });

              // TODO 以下、「表紙」と「本文」で処理を共通化したい
              // 「表紙」のrect情報を展開
              if(tmpCoverForms) {
                for(let i=0; i<tmpCoverForms.length; i++) {
                  let tmpFormDetail = undefined;
                  for(let j=0; j<formDetailModel.forms.length; j++) {
                    if(formDetailModel.forms[j].class=="COVER") {
                      // レスポンスの画像情報をFormServiceに設定
                      $scope.page.formatFB = formDetailModel.forms[j].image.format;
                      $scope.page.imgDataFB = formDetailModel.forms[j].image.data;
                      $scope.page.imgSrcFB = 'data:image/'+ $scope.page.formatFB + ';base64,' + $scope.page.imgDataFB;
                      tmpFormDetail = formDetailModel.forms[j];
                    }
                  }

                  if(tmpFormDetail) {
                    // キー情報設定
                    tmpCoverForms[i].idKey = 'F' + i;

                    // OCR読み取り対象矩形
                    if(tmpFormDetail.items) {
                      for(let j=0; j<tmpFormDetail.items.length; j++) {
                        if(tmpCoverForms[i].name===tmpFormDetail.items[j].name) {
                          tmpCoverForms[i].rect = tmpFormDetail.items[j].rect;
                          tmpCoverForms[i].fixedPos = tmpFormDetail.items[j].fixedPos;
                          tmpCoverForms[i].targetRect = self.parseViewRect(tmpFormDetail.items[j].targetRect, orgImgWidth);
                          tmpCoverForms[i].uncreate = false;
                        }
                      }
                    }
                    // 画像切り取り用矩形
                    if(tmpFormDetail.scraps) {
                      for(let j=0; j<tmpFormDetail.scraps.length; j++) {
                         if(tmpCoverForms[i].name===tmpFormDetail.scraps[j].name) {
                           tmpCoverForms[i].targetRect = self.parseViewRect(tmpFormDetail.scraps[j].targetRect, orgImgWidth);
                           tmpCoverForms[i].uncreate = false;
                         }
                       }
                     }
                   }
                 }
               }

               // 「本文」のrect情報を展開
               if(tmpContentForms) {
                 for(let i=0; i<tmpContentForms.length; i++) {
                   let tmpFormDetail = undefined;
                   for(let j=0; j<formDetailModel.forms.length; j++) {
                     if(formDetailModel.forms[j].class=="CONTENT") {
                       // レスポンスの画像情報をFormServiceに設定
                       $scope.page.formatBB = formDetailModel.forms[j].image.format;
                       $scope.page.imgDataBB = formDetailModel.forms[j].image.data;
                       $scope.page.imgSrcBB = 'data:image/'+ $scope.page.formatBB + ';base64,' + $scope.page.imgDataBB;
                       tmpFormDetail = formDetailModel.forms[j];
                     }
                   }

                   if(tmpFormDetail) {
                     // キー情報設定
                     tmpContentForms[i].idKey = 'B' + i;

                     // OCR読み取り対象矩形
                     if(tmpFormDetail.items) {
                       for(let j=0; j<tmpFormDetail.items.length; j++) {
                         if(tmpContentForms[i].name===tmpFormDetail.items[j].name) {
                           tmpContentForms[i].rect = tmpFormDetail.items[j].rect;
                           tmpContentForms[i].fixedPos = tmpFormDetail.items[j].fixedPos;
                           tmpContentForms[i].targetRect = self.parseViewRect(tmpFormDetail.items[j].targetRect, orgImgWidth);
                           tmpContentForms[i].uncreate = false;
                         }
                       }
                     }
                     // 画像切り取り用矩形
                     if(tmpFormDetail.scraps) {
                       for(let j=0; j<tmpFormDetail.scraps.length; j++) {
                         if(tmpContentForms[i].name===tmpFormDetail.scraps[j].name) {
                           tmpContentForms[i].targetRect = self.parseViewRect(tmpFormDetail.scraps[j].targetRect, orgImgWidth);
                           tmpContentForms[i].uncreate = false;
                         }
                       }
                     }
                   }
                 }
               }
               // *******************************  TODO 関数化したい↑
               
               // 用紙の権限設定
               let isPublicForm = false;
               if(formDetailModel.tags[1] === "PUBLIC") {
                 isPublicForm = true;
               }

               // レスポンス情報をFormServiceに設定
               $scope.page.formId = obj.formDetailModel.formId;
               $scope.page.formName = obj.formDetailModel.name;
               $scope.page.formDescription = obj.formDetailModel.description;
               $scope.page.isPublicForm = isPublicForm;
               $scope.page.tags = obj.formDetailModel.tags;
               $scope.page.coverForms = tmpCoverForms;
               $scope.page.contentForms = tmpContentForms;

               // serverMsgAreaリセット
               $scope.serverMsgReset();
               // ローディング画面を閉じる処理
               $scope.endLoading();

               // フォーム編集画面に遷移
               $state.go('form-edit');
             }

           // アクセス失敗
           }, function() {
             // serverMsgAreaリセット
             $scope.serverMsgReset();

             $scope.server.error = true;
             // 権限のないフォームへのアクセス
             if($scope.httpStatus==403) {
               $translate(['serverMsg.invalid-access'])
               .then(function (translations) {
                 $scope.server.msg = translations['serverMsg.invalid-access'];
               });
             // 指定されたIDのフォームが存在しない
             } else if($scope.httpStatus==404) {
               $translate(['serverMsg.form-not-exist'])
               .then(function (translations) {
                 $scope.server.msg = translations['serverMsg.form-not-exist'];
              });
              // サーバーエラー
              } else {
                $translate(['serverMsg.access-err'])
                .then(function (translations) {
                  $scope.server.msg = translations['serverMsg.access-err'];
                });
              }
              // ローディング画面を閉じる処理
              $scope.endLoading();
            }
          );

          // rectの値を表示するサイズに変換
          self.parseViewRect = function(targetRect, orgImgWidth) {
            // 表示画像幅を550px固定で計算
            let returnRect = new Array();
            angular.forEach(targetRect, function(rect) {
              returnRect.push(rect * (550 / orgImgWidth))
            });
            return returnRect
          }
        }
      })
    }
}]);

// フォーム削除
app.controller('FormDeleteCtrl', [ '$scope', '$translate', '$state', '$filter', 'FormListService', 'ServerMsgService',
  function($scope, $translate, $state, $filter, FormListService, ServerMsgService) {
    const self = this;

    // factoryからデータ共有
    $scope.server = ServerMsgService;
    $scope.form = FormListService;

    // フォームの削除APIを実行
    self.deleteForm = function() {

      let loadingMsg = "";
      // 読込中メッセージを設定
      $translate(['loadingMsg.form-delete'])
      .then(function (translations) {
        loadingMsg = translations['loadingMsg.form-delete'];
      })
      .then(function() {
        // ローディング画面を表示
        $scope.startLoading(loadingMsg);
      });

      // フォームの削除APIを実行
      $scope.executeApi('DELETE', '/api/forms/' + $scope.form.formInfo.formId)
        .then(
          // アクセス成功
          function() {
            // serverMsgAreaリセット
            $scope.serverMsgReset();

            // 削除完了時のメッセージに使用するデータを用意
            let targetFormData = {
              formName: $scope.form.formInfo.name,
              type: ""
            };
            $translate(['delete'])
            .then(function (translations) {
              targetFormData.type = translations['delete'];
            })
            .then(function() {
              $scope.server.info = true;
              $translate(['serverMsg.form-fix'], targetFormData)
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.form-fix'];
              });
            })
            .then(function() {
              // ローディング画面を閉じる処理
              $scope.endLoading();
              $state.reload();
            });

          // アクセス失敗
          }, function() {
            // serverMsgAreaリセット
            $scope.serverMsgReset();

            $scope.server.error = true;
            // 権限のないフォームへのアクセス
            if($scope.httpStatus==403) {
              $translate(['serverMsg.invalid-access'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.invalid-access'];
              });
            // 指定されたIDのフォームが存在しない
            } else if($scope.httpStatus==404) {
              $translate(['serverMsg.form-not-exist'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.form-not-exist'];
              });
            // OCR実行で使用されているフォーム
            } else if($scope.httpStatus==409) {
              $translate(['serverMsg.being-used-form'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.being-used-form'];
              });
            // サーバーエラー
            } else {
              $translate(['serverMsg.access-err'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.access-err'];
              });
            }
            // ローディング画面を閉じる処理
            $scope.endLoading();
          }
        );
    }

}]);

// フォームプレビュー
app.controller('FormPreviewCtrl', ['$scope', 'FormListService',
  function($scope, FormListService) {
    const self = this;

    // factoryからデータ共有
    $scope.form = FormListService;

}]);

