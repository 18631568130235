let app = angular.module('app');

// ユーザー新規登録/更新
app.controller('UserCtrl', ['$scope', '$controller', '$http', '$window', '$translate', '$state', '$q', 'ServerMsgService',
  function($scope, $controller, $http, $window, $translate, $state, $q, ServerMsgService) {

    const self = this;

    // factoryからデータ共有
    $scope.server = ServerMsgService;

    let loginId = "";

    // ユーザー情報を取得するAPIを実行する
    $scope.executeApi('GET', 'api/accounts/my')
    .then(
      // アクセス成功
      function() {
        // ログインIDが書き換えられたときの対策に、コントローラーでログインIDを保持する
        loginId = $scope.response.data.loginId;

        if(typeof loginId !== "undefined") {
          $scope.loginId = $scope.response.data.loginId;
          $scope.name = $scope.response.data.name;
          $scope.email = $scope.response.data.email;
          // 【TODO】通知設定は次期対応のためコメントアウト
          //$scope.notify = $scope.response.data.notify;
        } else {
          $window.location.href = '/views/login.html';
        }
      }
    );

    $scope.inputPage = true;

    let userData = new Object();
    let accountObj = new Object();
    let oldPass = "";
    let isUpdatePass = "";

    // 【ユーザー新規登録画面】入力エラーがない場合は、エラーメッセージ表示フラグをfalseにし、ページを切り替える
    // ある場合は、エラーメッセージ表示フラグをtrueにする。
    self.checkCreateInput = function() {
      if( !($scope.inputForm.$invalid || $scope.passMatchLoginId || $scope.passNotMatch || $scope.emailNotMatch) ) {
        $scope.invalid = false;

        // 入力された値を変数に格納
        accountObj = {
          loginId: loginId,
          name: $scope.inputForm.name.$viewValue,
          email: $scope.inputForm.email.$viewValue,
          // 【TODO】通知設定は次期対応のためコメントアウト
//          notify: $scope.inputForm.notify.$viewValue,
        };
        userData = {
          account: accountObj,
          password: $scope.inputForm.password.$viewValue,
        };

        // パスワードのマスキング処理
        let maskPass = "";
        for(let idx = 0; idx <= $scope.inputForm.password.$viewValue.length; idx++) {
          maskPass = maskPass + "*";
        };

        // 確認画面に切り替え
        $scope.inputPage = false;

        // 確認画面に入力された値を表示(パスワードはマスキングしたもの)
        $scope.idConfirm = loginId;
        $scope.passwordConfirm = maskPass;
        $scope.nameConfirm = userData.account.name;
        $scope.emailConfirm = userData.account.email;
        // 【TODO】通知設定は次期対応のためコメントアウト
//        $scope.notifyConfirm = userData.account.notify;
      } else {
          $scope.invalid = true;
      }
    };

    // 【ユーザー更新画面】入力エラーがない場合は、エラーメッセージ表示フラグをfalseにし、ページを切り替える
    // ある場合は、エラーメッセージ表示フラグをtrueにする。
    // TODO できれば登録画面と個人設定で同じ関数を使いたい
    self.checkUpdateInput = function() {
      let passwordUpdatePromise = function() {
        let d = $q.defer();
        if($scope.inputForm.updatePass.$viewValue) {
          // 既存パスワードが入力されているかチェックする
          if($scope.inputForm.oldPass.$viewValue) {
            // 既存パスワード確認APIを実行するためのloginIdとパスワードを設定する
            const passwordCheckData = {
              loginId: loginId,
              password: $scope.inputForm.oldPass.$viewValue,
            };
            // 既存パスワード確認のAPIを実行
            $scope.executeApi('PUT', '/api/accounts/my/check', passwordCheckData)
            .then(
              // アクセス完了
              function() {
                $scope.oldPassNotMatch = $scope.response.data.isPasswordMatchError;
                d.resolve();
              // アクセス失敗
              }, function() {
                $scope.serverMsgReset();
                $scope.server.error = true;

                // ログインユーザー以外のIDへのアクセス
                if($scope.httpStatus==403) {
                  $translate(['serverMsg.invalid-access'])
                  .then(function (translations) {
                    $scope.server.msg = translations['serverMsg.invalid-access'];
                  });
                // サーバーエラー
                } else {
                  $translate(['serverMsg.access-err'])
                  .then(function (translations) {
                    $scope.server.msg = translations['serverMsg.access-err'];
                  })
                }
              })
          } else {
            $scope.oldPassNotMatch = true;
            d.resolve();
          }
        } else {
          $scope.passNotMatch = false;
          $scope.oldPassNotMatch = false;
          d.resolve();
        }
        return d.promise;
      }
      passwordUpdatePromise()
      .then(function() {
        if( !($scope.inputForm.$invalid || $scope.oldPassNotMatch || $scope.passMatchLoginId || $scope.passNotMatch || $scope.emailNotMatch) ) {
          $scope.invalid = false;

          // 入力された値を変数に格納
          accountObj = {
            loginId: loginId,
            name: $scope.inputForm.name.$viewValue,
            email: $scope.inputForm.email.$viewValue,
            // 【TODO】通知設定は次期対応のためコメントアウト
//            notify: $scope.inputForm.notify.$viewValue,
          };
          isUpdatePass = $scope.inputForm.updatePass.$viewValue;
          let maskPass = "";
          if(isUpdatePass) {
            oldPass = $scope.inputForm.oldPass.$viewValue;
            userData = {
              account: accountObj,
              password: $scope.inputForm.password.$viewValue,
            };
            // パスワードのマスキング処理
            for(let idx = 0; idx <= $scope.inputForm.password.$viewValue.length; idx++) {
              maskPass = maskPass + "*";
            };
          } else {
            maskPass="";
            oldPass="";
            userData = {
              account: accountObj,
              password: "",
            };
          };

          // 確認画面に切り替え
          $scope.inputPage = false;

          // 確認画面に入力された値を表示(パスワードはマスキングしたもの)
          $scope.idConfirm = loginId;
          $scope.passwordConfirm = maskPass;
          $scope.nameConfirm = userData.account.name;
          $scope.emailConfirm = userData.account.email;
          // 【TODO】通知設定は次期対応のためコメントアウト
//          $scope.notifyConfirm = userData.account.notify;
        } else {
            $scope.invalid = true;
        }
      })
    };

    // パスワードからフォーカスが外れたときを監視し、ログインIDと一致する場合にフラグをtrueにする
    self.checkPassMatch = function () {
      if($scope.inputForm.password.$viewValue == loginId) {
        $scope.passMatchLoginId = true;
      } else {
        $scope.passMatchLoginId = false;
      }
    };

    // パスワードとパスワード確認項目の変更を監視し、パスワードと一致しない場合にフラグをtrueにする
    self.checkPassNotMatch = function () {
      if($scope.inputForm.password.$viewValue != $scope.inputForm.passwordCheck.$viewValue) {
        $scope.passNotMatch = true;
      } else {
        $scope.passNotMatch = false;
      }
    };
    // メールアドレス確認項目の変更を監視し、メールアドレスと一致しない場合にフラグをtrueにする
    self.checkEmail = function () {
      if($scope.inputForm.email.$viewValue != $scope.inputForm.emailCheck.$viewValue) {
        $scope.emailNotMatch = true;
      } else {
        $scope.emailNotMatch = false;
      }
    };

    // 【ユーザー新規登録画面】ポップアップを閉じた後、ログアウトする処理
    // TODO common.jsのメソッドを使いたい
    self.doCreateCancel = function () {
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
      $scope.executeApi('POST', '/api/logout')
      .then(function() {
        $window.location.href = '/';
      });
    };

    // 【個人設定画面】ポップアップを閉じた後、OCR実行画面に戻る処理
    self.doUpdateCancel = function() {
      closeModal();
      $state.go('ocr-jobs');
    };

    // ユーザー登録処理(更新)の実行
    self.userRegist = function (isUpdate) {

      // 読込中メッセージを設定
      let setLoadingMsg = function(isUpdate) {
        let d = $q.defer();
        if(isUpdate) {
          $translate(['loadingMsg.user-update'])
          .then(function (translations) {
            loadingMsg = translations['loadingMsg.user-update'];
            d.resolve();
          })
        } else {
          $translate(['loadingMsg.user-create'])
          .then(function (translations) {
            loadingMsg = translations['loadingMsg.user-create'];
            d.resolve();
          })
        }
        return d.promise;
      };

      let loadingMsg = "";
      setLoadingMsg(isUpdate)
      .then(function() {
        // ローディング画面を表示
        $scope.startLoading(loadingMsg);
      });

      $scope.executeApi('PUT', '/api/accounts/my', userData)
      .then(
        // 登録完了
        function() {
          if(isUpdate) {
            // serverMsgAreaリセット
            $scope.serverMsgReset();

            $scope.server.info = true;
            $translate(['serverMsg.user-update'])
            .then(function (translations) {
              $scope.server.msg = translations['serverMsg.user-update'];
            });
            $state.go('ocr-jobs');
          } else {
            $window.location.href = '/#!/forms/new?userCreate=true';
          };
          // ローディング画面を閉じる処理
          $scope.endLoading();
        // 登録失敗
        }, function() {
          // serverMsgAreaリセット
          $scope.serverMsgReset();
          $scope.server.error = true;

          // HTTPステータスごとのエラーメッセージを用意
          // 該当ユーザーが存在しない
          if($scope.httpStatus==409) {
            $translate(['serverMsg.user-not-exist'])
            .then(function (translations) {
              $scope.server.msg = translations['serverMsg.user-not-exist'];
            });
          // ログインユーザー以外のIDへのアクセス
          } else if($scope.httpStatus==403) {
            $translate(['serverMsg.invalid-access'])
            .then(function (translations) {
              $scope.server.msg = translations['serverMsg.invalid-access'];
            });
          // サーバーエラー
          } else {
            $translate(['serverMsg.access-err'])
            .then(function (translations) {
              $scope.server.msg = translations['serverMsg.access-err'];
            })
          }
          // ローディング画面を閉じる処理
          $scope.endLoading();
        }
      );
    };

    // 確認画面から入力画面に戻る
    self.backPage = function (isUpdate) {
      $scope.inputPage = true;
      $scope.id = userData.account.loginId;
      $scope.password = userData.password;
      $scope.name = userData.account.name;
      $scope.email = userData.account.email;
      // 【TODO】通知設定は次期対応のためコメントアウト
//      $scope.notify = userData.account.notify;
      // ｢個人設定｣画面か｢ユーザー新規登録｣画面か判定
      if(isUpdate) {
        $scope.updatePass = isUpdatePass;
        $scope.oldPass = oldPass;
      }

      // serverMsgAreaリセット
      $scope.serverMsgReset();
    };

}]);

