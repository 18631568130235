let app = angular.module('app');

app.controller('ItemsControl', ['$scope', '$http', '$translate', '$window',
  function($scope, $http, $translate, $window) {
    const self = this;

    // 項目マスタ取得
    self.getItems = function(init) {

      // セッションチェック(TODO:関数化したい)
      $scope.executeApi('GET', '/api/accounts/session')
      .then(function() {
        const res = $scope.response.data;
        if(typeof res.session === "undefined") {
          $window.location.href = '/views/login.html';
        } else {
          // 読込中メッセージを設定
          let loadingMsg = "";
          $translate(['loadingMsg.manage-items-list'])
          .then(function (translations) {
            loadingMsg = translations['loadingMsg.manage-items-list'];
          })
          .then(function() {
            // ローディング画面を表示
            $scope.startLoading(loadingMsg);
          });

          $scope.executeApi('GET', '/api/items')
          .then(
            function() {
              $scope.items = $scope.response.data;
              // 日付をフォーマットし、再設定
              angular.forEach($scope.items, function(item) {
                item.created = formatDate(new Date(item.created * 1000), 'yyyy/MM/dd hh:mm:ss');
                item.updated = formatDate(new Date(item.updated * 1000), 'yyyy/MM/dd hh:mm:ss');
              });

              // 表示順にソート
              $scope.items.sort(function(a,b){
                if( a.appData.displayOrder < b.appData.displayOrder ) {
                  return -1;
                }
                if( a.appData.displayOrder > b.appData.displayOrder ) {
                  return 1;
                }
                return 0;
              });

              // 設定内容リセット
              self.resetItem();
              // serverMsgAreaリセット（初期表示時のみ）
              if(init) {
                $scope.serverMsgReset();
              }
              // ローディング画面を閉じる処理
              $scope.endLoading();

            }, function() {
              // 設定内容リセット
              self.resetItem();
              // serverMsgAreaリセット
              $scope.serverMsgReset();

              $scope.server.error = true;
              $translate(['serverMsg.access-err'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.access-err'];
              });
              // ローディング画面を閉じる処理
              $scope.endLoading();
            }
          );
        }
      })
    };

    // 項目登録
    self.registerItems = function() {

      // セッションチェック(TODO:関数化したい)
      $scope.executeApi('GET', '/api/accounts/session')
      .then(function() {
        const res = $scope.response.data;
        if(typeof res.session === "undefined") {
          $window.location.href = '/views/login.html';
        } else {
          // 読込中メッセージを設定
          let loadingMsg = "";
          $translate(['loadingMsg.manage-items-create'])
          .then(function (translations) {
            loadingMsg = translations['loadingMsg.manage-items-create'];
          })
          .then(function() {
            // ローディング画面を表示
            $scope.startLoading(loadingMsg);
          });

          // リクエストJSON作成
          let reqData = new Object();
          reqData.name = $scope.name;
          reqData.type = $scope.type;
          reqData.appData = $scope.appData;
          reqData.inputMask = $scope.inputMask;
          reqData.wordOrder = $scope.wordOrder;
          // 型を判定し、文字列だった場合はsplit(',')で配列に変換
          const aliases = $scope.aliases;
          if (aliases instanceof Array) {
            reqData.aliases = aliases;
          } else {
            reqData.aliases = aliases.split(',');
          }
          reqData.tags = new Array();
          reqData.tags[0] = $scope.username;
          reqData.tags[1] = $scope.username;

          $scope.executeApi('POST', '/api/items', reqData)
          .then(
            function() {
              // serverMsgAreaリセット
              $scope.serverMsgReset();
              // 一覧再取得
              self.getItems(false);
              // ローディング画面を閉じる処理
              $scope.endLoading();

            }, function() {
              // serverMsgAreaリセット
              $scope.serverMsgReset();

              $scope.server.error = true;
              $translate(['serverMsg.access-err'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.access-err'];
              });

              // 一覧再取得
              self.getItems(false);
              // ローディング画面を閉じる処理
              $scope.endLoading();
            });
          }
        }
      )
    };

    // 項目更新
    self.updateItems = function() {

      // セッションチェック(TODO:関数化したい)
      $scope.executeApi('GET', '/api/accounts/session')
      .then(function() {
        const res = $scope.response.data;
        if(typeof res.session === "undefined") {
          $window.location.href = '/views/login.html';
        } else {
          // 読込中メッセージを設定
          let loadingMsg = "";
          $translate(['loadingMsg.manage-items-update'])
          .then(function (translations) {
            loadingMsg = translations['loadingMsg.manage-items-update'];
          })
          .then(function() {
            // ローディング画面を表示
            $scope.startLoading(loadingMsg);
          });

          // リクエストJSON作成
          let reqData = new Object();
          reqData.name = $scope.name;
          reqData.type = $scope.type;
          reqData.appData = $scope.appData;
          reqData.inputMask = $scope.inputMask;
          reqData.wordOrder = $scope.wordOrder;
          // 型を判定し、文字列だった場合はsplit(',')で配列に変換
          const aliases = $scope.aliases;
          if (aliases instanceof Array) {
            reqData.aliases = aliases;
          } else {
            reqData.aliases = aliases.split(',');
          }
          reqData.tags = $scope.tags;
          reqData.tags[1] = $scope.username;

          $scope.executeApi('PUT', '/api/items/' +  $scope.itemId, reqData)
          .then(
            function() {
              // serverMsgAreaリセット
              $scope.serverMsgReset();
              // 一覧再取得
              self.getItems(false);
              // ローディング画面を閉じる処理
              $scope.endLoading();

            }, function() {
              // serverMsgAreaリセット
              $scope.serverMsgReset();

              $scope.server.error = true;
              $translate(['serverMsg.access-err'])
              .then(function (translations) {
                $scope.server.msg = translations['serverMsg.access-err'];
              });

              // 一覧再取得
              self.getItems(false);
              // ローディング画面を閉じる処理
              $scope.endLoading();
            });
          }
        }
      )
    };

    // 項目削除
    self.deleteItems = function() {

      // 読込中メッセージを設定
      let loadingMsg = "";
      $translate(['loadingMsg.manage-items-delete'])
      .then(function (translations) {
        loadingMsg = translations['loadingMsg.manage-items-delete'];
      })
      .then(function() {
        // ローディング画面を表示
        $scope.startLoading(loadingMsg);
      });

      $scope.executeApi('DELETE', '/api/items/' +  $scope.itemId)
      .then(
        function() {
          // serverMsgAreaリセット
          $scope.serverMsgReset();
          // 一覧再取得
          self.getItems(false);
          // ローディング画面を閉じる処理
          $scope.endLoading();

        }, function() {
          // serverMsgAreaリセット
          $scope.serverMsgReset();
          $scope.server.error = true;

          // 409以外の場合、システムエラー
          if($scope.httpStatus!=409) {
            $translate(['serverMsg.access-err'])
            .then(function (translations) {
              $scope.server.msg = translations['serverMsg.access-err'];
            });

          // 409の場合、用紙で参照済みエラー
          } else {
            $translate(['serverMsg.being-used-item'])
            .then(function (translations) {
              $scope.server.msg = translations['serverMsg.being-used-item'];
            });
          }

          // 一覧再取得
          self.getItems(false);
          // ローディング画面を閉じる処理
          $scope.endLoading();
        }
      );
    };

    // 項目内容初期化
    self.resetItem = function() {
      $scope.itemId = undefined;
      $scope.name = undefined;
      $scope.aliases = undefined;
      $scope.type = undefined;
      $scope.inputMask = undefined;
      $scope.wordOrder = undefined;
      $scope.tags = undefined;
      $scope.appData = undefined;
      $scope.created = undefined;
      $scope.updated = undefined;
    }

    // 項目選択
    self.selectItem = function(targetIdx) {
      $scope.itemId = $scope.items[targetIdx].itemId;
      $scope.name = $scope.items[targetIdx].name;
      $scope.aliases = $scope.items[targetIdx].aliases;
      $scope.type = $scope.items[targetIdx].type;
      $scope.inputMask = $scope.items[targetIdx].inputMask;;
      $scope.wordOrder = $scope.items[targetIdx].wordOrder;;
      $scope.tags = $scope.items[targetIdx].tags;
      $scope.appData = $scope.items[targetIdx].appData;
      $scope.created = $scope.items[targetIdx].created;
      $scope.updated = $scope.items[targetIdx].updated;
    }

    // 初期表示処理
    self.getItems(true);
}]);

